import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as invoiceActions from '../invoice/reduxStore/action';
import * as paymentActions from './reduxStore/action';
import MSText from '../ui/typography/MSText';
import XXSText from '../ui/typography/XXSText';
import MoreMenu from '../common/MoreMenu';
import DynamicCheckbox from '../ui/DynamicCheckbox';
import Checkbox from '@mui/material/Checkbox';
import QRcode from 'react-qr-code';
import Plus from '../../images/icons/customSvg/plus';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import { isIbanNumber } from '../../helper/helpers';
import { EXTERNAL_PAYMENT_LINKS } from '../../constants/enum';
function InvoicePayment({
  closeDynamicModal,
  getBankDetailsList,
  deleteBankDetails,
  getBankDetails,
  invoiceData,
  getInvoiceDetails,
  onEditBankDetails,
  setStep,
  invoicePaymentDetails,
  isTemporary
}) {
  const [hsbcChecked, setHsbcChecked] = useState(getInvoiceDetails?.paymentDetails?.hsbcDetails?.isSelected);
  const [customPaymentDetails, setCustomPaymentDetails] = useState(
    getInvoiceDetails?.paymentDetails?.customPaymentDetails?.isSelected
  );
  const [customTextState, setCustomTextState] = useState(
    getInvoiceDetails?.paymentDetails?.customTextDetails?.isSelected
  );
  const [paypalChecked, setPaypalChecked] = useState(getInvoiceDetails?.paymentDetails?.paypalDetails?.isSelected);
  const [bankPaymentDetails, setBankPaymentDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const onDeleteBankDetails = async (bankId) => {
    if (!isTemporary) {
      setIsLoading(true);
      await deleteBankDetails(bankId);
      const filteredBankDetailsList = getInvoiceDetails?.paymentDetails?.bankDetails?.filter(
        (bankDetails) => bankDetails?._id !== bankId
      );
      bankDetailsList();
      await invoiceData({
        paymentDetails: {
          ...getInvoiceDetails.paymentDetails,
          bankDetails: filteredBankDetailsList
        }
      });
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const filteredBankDetailsList = getInvoiceDetails?.paymentDetails?.bankDetails?.filter(
        (bankDetails) => bankDetails?._id !== bankId
      );

      if (getInvoiceDetails?.paymentDetails?.bankDetails?.length > 0) {
        setBankPaymentDetails(filteredBankDetailsList);
        await invoiceData({
          paymentDetails: {
            bankDetails: filteredBankDetailsList
          }
        });
        setIsLoading(false);
      } else {
        setStep(0);
      }
    }
  };

  const handleCheckboxSelection = async (bankId, selected) => {
    if (!isTemporary) {
      const bankDetail = await getBankDetails(bankId);
      if (!selected) {
        await invoiceData({
          paymentDetails: {
            ...getInvoiceDetails.paymentDetails,
            bankDetails: getInvoiceDetails?.paymentDetails?.bankDetails
              ? [...getInvoiceDetails?.paymentDetails?.bankDetails, bankDetail?.data?.data]
              : [bankDetail?.data?.data]
          }
        });
      }
      if (selected) {
        const filteredBankDetailsList = getInvoiceDetails?.paymentDetails?.bankDetails?.filter(
          (bankDetails) => bankDetails?._id !== bankId
        );
        await invoiceData({
          paymentDetails: {
            ...getInvoiceDetails?.paymentDetails,
            bankDetails: filteredBankDetailsList
          }
        });
      }
    } else {
      setIsLoading(true);
      if (selected) {
        const filteredBankDetailsList = getInvoiceDetails?.paymentDetails?.bankDetails?.map((bankDetails) => {
          if (bankDetails?._id === bankId) {
            return { ...bankDetails, isSelected: false };
          }
          return { ...bankDetails, isSelected: false };
        });
        setBankPaymentDetails(filteredBankDetailsList);
        await invoiceData({
          paymentDetails: {
            ...getInvoiceDetails.paymentDetails,
            bankDetails: filteredBankDetailsList
          }
        });
        setIsLoading(false);
      }
      if (!selected) {
        const filteredBankDetailsList = getInvoiceDetails?.paymentDetails?.bankDetails?.map((bankDetails) => {
          if (bankDetails?._id === bankId) {
            return { ...bankDetails, isSelected: true };
          }
          return { ...bankDetails, isSelected: false };
        });
        setBankPaymentDetails(filteredBankDetailsList);
        await invoiceData({
          paymentDetails: {
            ...getInvoiceDetails.paymentDetails,
            bankDetails: filteredBankDetailsList
          }
        });
        setIsLoading(false);
      }
    }
  };
  const handleAddAccountDetail = () => {
    setStep(0);
  };
  useEffect(() => {
    bankDetailsList();
  }, [invoicePaymentDetails?.editBankDetailsResponse]);
  const bankDetailsList = async () => {
    if (!isTemporary) {
      const bankDetailsList = await getBankDetailsList();
      const bankDetailsListWithSelection = bankDetailsList?.data?.data?.map((obj) => ({
        ...obj,
        isSelected: getInvoiceDetails?.paymentDetails?.bankDetails?.find((bankDetail) => bankDetail?._id === obj?._id)
          ? true
          : false
      }));
      setBankPaymentDetails(bankDetailsListWithSelection);
      setIsLoading(false);
      if (
        bankDetailsList?.data?.data?.length ||
        getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink ||
        getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink
      ) {
        setStep(5);
      } else setStep(0);
    } else {
      if (getInvoiceDetails?.paymentDetails?.bankDetails) {
        setStep(5);
        setBankPaymentDetails(getInvoiceDetails?.paymentDetails?.bankDetails);
      } else {
        setStep(0);
      }
      setIsLoading(false);
    }
  };
  const handleHsbcCheckbox = async () => {
    if (!hsbcChecked) {
      await invoiceData({
        paymentDetails: {
          ...getInvoiceDetails.paymentDetails,
          hsbcDetails: { ...getInvoiceDetails?.paymentDetails?.hsbcDetails, isSelected: true }
        }
      });
    }
    if (hsbcChecked) {
      await invoiceData({
        paymentDetails: {
          ...getInvoiceDetails.paymentDetails,
          hsbcDetails: { ...getInvoiceDetails?.paymentDetails?.hsbcDetails, isSelected: false }
        }
      });
    }
    setHsbcChecked(!hsbcChecked);
  };
  const handleCustomPaymentCheckbox = async () => {
    if (!customPaymentDetails) {
      await invoiceData({
        paymentDetails: {
          ...getInvoiceDetails.paymentDetails,
          customPaymentDetails: { ...getInvoiceDetails?.paymentDetails?.customPaymentDetails, isSelected: true }
        }
      });
    }
    if (customPaymentDetails) {
      await invoiceData({
        paymentDetails: {
          ...getInvoiceDetails.paymentDetails,
          customPaymentDetails: { ...getInvoiceDetails?.paymentDetails?.customPaymentDetails, isSelected: false }
        }
      });
    }
    setCustomPaymentDetails(!customPaymentDetails);
  };
  const handlePaypalCheckbox = async () => {
    if (!paypalChecked) {
      await invoiceData({
        paymentDetails: {
          ...getInvoiceDetails.paymentDetails,
          paypalDetails: { ...getInvoiceDetails?.paymentDetails?.paypalDetails, isSelected: true }
        }
      });
    }
    if (paypalChecked) {
      await invoiceData({
        paymentDetails: {
          ...getInvoiceDetails.paymentDetails,
          paypalDetails: { ...getInvoiceDetails?.paymentDetails?.paypalDetails, isSelected: false }
        }
      });
    }
    setPaypalChecked(!paypalChecked);
  };

  const handleCustomTextCheckbox = async () => {
    await invoiceData({
      paymentDetails: {
        ...getInvoiceDetails.paymentDetails,
        customTextDetails: {
          ...getInvoiceDetails?.paymentDetails?.customTextDetails,
          isSelected: !customTextState
        }
      }
    });
    setCustomTextState(!customTextState);
  };
  return (
    <>
      <PrimaryButton
        id="payment-details-list-add-btn"
        className="invoice-button mb-4"
        onClick={handleAddAccountDetail}
        caption={
          <div className="flex items-center">
            <div className="w-6">
              <Plus />
            </div>
            <MSText textColor="text-white" fontWeight="text-bold" title="Add payment details" />
          </div>
        }
        fontWeight="text-bold"
        type="small"
      />
      {isLoading ? (
        <StatrysLoader />
      ) : (
        bankPaymentDetails?.map((bankData, index) => (
          <div
            className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}
            key={index + 1}
          >
            <DynamicCheckbox
              id={bankData?._id}
              handleCheckboxSelection={(id, selected) => handleCheckboxSelection(id, selected)}
              isSelected={bankData?.isSelected}
            />
            <div className="w-full">
              <div className="flex justify-between items-center mb-3">
                <XXSText fontWeight="text-bold" title={bankData?.alias} />
                <div className="flex items-center -mr-3 -mt-3">
                  <div>
                    <MoreMenu
                      onEditBankDetails={() => onEditBankDetails(bankData?._id)}
                      onDeleteBankDetails={() => onDeleteBankDetails(bankData?._id)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div>
                  {bankData?.bankCountry && (
                    <div className="flex">
                      <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Bank country:" />
                      <MSText className="mb-2" textColor="text-gray-300" title={bankData?.bankCountry} />
                    </div>
                  )}
                  {bankData?.bankAddress && (
                    <div className="flex">
                      <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Bank address:" />
                      <MSText className="mb-2" textColor="text-gray-300" title={bankData?.bankAddress} />
                    </div>
                  )}
                  {bankData?.fullName && (
                    <div className="flex">
                      <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Full Name:" />
                      <MSText className="mb-2" textColor="text-gray-300" title={bankData?.fullName} />
                    </div>
                  )}
                  {bankData?.bankName && (
                    <div className="flex">
                      <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Bank name:" />
                      <MSText className="mb-2" textColor="text-gray-300" title={bankData?.bankName} />
                    </div>
                  )}
                  {bankData?.accountNumberOrIban && (
                    <div className="flex">
                      <MSText
                        className="mb-2 bank-details-column whitespace-pre-line"
                        textColor="text-gray-700"
                        title={isIbanNumber(bankData?.accountNumberOrIban)}
                      />
                      <MSText className="mb-2" textColor="text-gray-300" title={bankData?.accountNumberOrIban} />
                    </div>
                  )}
                  {bankData?.swiftCode && (
                    <div className="flex">
                      <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="SWIFT/BIC code:" />
                      <MSText className="mb-2" textColor="text-gray-300" title={bankData?.swiftCode} />
                    </div>
                  )}
                  {bankData?.routingCode && (
                    <div className="flex">
                      <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Routing Code:" />
                      <MSText className="mb-2" textColor="text-gray-300" title={bankData?.routingCode} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                {bankData?.bankCode && (
                  <div className="flex mr-8">
                    <MSText className="bank-details-column" textColor="text-gray-700" title="Bank code:" />
                    <MSText textColor="text-gray-300" title={bankData?.bankCode} />
                  </div>
                )}
                {bankData?.branchCode && (
                  <div className="flex mb-2">
                    <MSText className="bank-details-column" textColor="text-gray-700" title="Branch code:" />
                    <MSText textColor="text-gray-300" title={bankData?.branchCode} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      )}
      {getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <Checkbox checked={paypalChecked} onChange={handlePaypalCheckbox} />
          <div className="w-full">
            <div className="flex justify-between items-center mb-3">
              <XXSText fontWeight="text-bold" title="PayPal" />
              <div className="flex items-center -mr-3 -mt-3">
                <div>
                  <MoreMenu
                    onEditBankDetails={() => setStep(2)}
                    onDeleteBankDetails={async () => {
                      await invoiceData({
                        paymentDetails: {
                          ...getInvoiceDetails?.paymentDetails,
                          paypalDetails: {}
                        }
                      });
                      await bankDetailsList();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center">
              {getInvoiceDetails?.paymentDetails?.paypalDetails?.showQrCode && (
                <div className="w-12 h-12 bg-coral-500">
                  <QRcode
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={`${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink}`}
                  />
                </div>
              )}

              <div>
                <MSText className="mb-2 bank-details-column" title="Pay with PayPal" />
                <MSText
                  textColor="text-coral-500"
                  fontWeight="text-bold"
                  title={`${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink}`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <Checkbox checked={hsbcChecked} onChange={handleHsbcCheckbox} />
          <div className="w-full">
            <div className="flex justify-between items-center mb-3">
              <XXSText fontWeight="text-bold" title="PayMe" />
              <div className="flex items-center -mr-3 -mt-3">
                <div>
                  <MoreMenu
                    onEditBankDetails={() => setStep(3)}
                    onDeleteBankDetails={() =>
                      invoiceData({
                        paymentDetails: {
                          ...getInvoiceDetails.paymentDetails,
                          hsbcDetails: {}
                        }
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center">
              {getInvoiceDetails?.paymentDetails?.hsbcDetails?.showQrCode && (
                <div className="w-12 h-12 bg-coral-500">
                  <QRcode
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={`${EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}${getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink}`}
                  />
                </div>
              )}

              <div>
                <MSText className="mb-2 bank-details-column" title="Pay with PayMe" />
                <MSText
                  textColor="text-coral-500"
                  fontWeight="text-bold"
                  title={`${EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}${getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink}`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLink && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <Checkbox checked={customPaymentDetails} onChange={handleCustomPaymentCheckbox} />
          <div className="w-full">
            <div className="flex justify-between items-center mb-3">
              <XXSText fontWeight="text-bold" title="Custom Payment" />
              <div className="flex items-center -mr-3 -mt-3">
                <div>
                  <MoreMenu
                    onEditBankDetails={() => setStep(4)}
                    onDeleteBankDetails={() =>
                      invoiceData({
                        paymentDetails: {
                          ...getInvoiceDetails.paymentDetails,
                          customPaymentDetails: {}
                        }
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center">
              <div>
                <MSText
                  className="mb-2 bank-details-column"
                  title={getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLabel}
                />
                <MSText
                  textColor="text-coral-500"
                  fontWeight="text-bold"
                  title={getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLink}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {getInvoiceDetails?.paymentDetails?.customTextDetails && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <Checkbox checked={customTextState} onChange={handleCustomTextCheckbox} />
          <div className="w-full">
            <MSText
              className="bank-details-column"
              title={getInvoiceDetails?.paymentDetails?.customTextDetails?.customText}
            />
          </div>
        </div>
      )}
      <div className="flex mt-8">
        <PrimaryButton
          id="payment-details-list-add-to-invoice"
          className="modal-save-button mr-4"
          caption="Add to invoice"
          onClick={() => closeDynamicModal()}
          fontWeight="text-bold"
          type="small"
        />
        <PrimaryButton
          id="payment-details-list-cancel-btn"
          bgColor="bg-white"
          className=" modal-cancel-button"
          color="text-gray-300"
          caption="Cancel"
          onClick={() => closeDynamicModal()}
          fontWeight="text-bold"
          type="small"
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData,
    invoicePaymentDetails: state?.invoicePaymentDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBankDetailsList: () => dispatch(paymentActions.getBankDetailsList()),
    deleteBankDetails: (bankId) => dispatch(paymentActions.deleteBankDetails(bankId)),
    getBankDetails: (bankId) => dispatch(paymentActions.getBankDetails(bankId)),
    addBankDetails: (bankDetails) => dispatch(paymentActions.addBankDetails(bankDetails)),
    editBankDetails: (bankDetails) => dispatch(paymentActions.editBankDetails(bankDetails)),
    invoiceData: (invoiceData) => dispatch(invoiceActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayment);

InvoicePayment.propTypes = {};
