import * as React from 'react';

import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Stack, Link, MenuItem, Divider } from '@mui/material';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { CONSTANT_NUMBER, INVOICE_STATUS, RESPONSIVENESS_BREAKPOINT_VALUE } from '../../../../constants/enum';
import Avatar from '../../../../components/ui/Avatar';
import { concatenateSelectedCurrency, stringTruncate } from '../../../../helper/helpers';

// ----------------------------------------------------------------------

InvoiceTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func
};

export default function InvoiceTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  onDuplicateRow,
  onClickSelectedRow,
  width
}) {
  const theme = useTheme();

  const { invoiceNumber, createDate, dueDate, status, contact, totalAmount, currency } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell
        className="cursor-pointer"
        onClick={onClickSelectedRow}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '16px 4px 16px -10px !important' : '16px',
          '&.MuiTableCell-root:first-of-type': {
            paddingLeft: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '8px' : '24px'
          }
        }}
      >
        <div className="mr-4">
          <Avatar avatarImage={contact?.profileImagePublic} avatarName={contact?.companyName?.charAt(0)} />
        </div>
        <Stack>
          <Typography variant="subtitle2" noWrap>
            {width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
              ? stringTruncate(contact?.companyName, CONSTANT_NUMBER.THIRTEEN)
              : contact?.companyName}
          </Typography>
          {width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
            ? stringTruncate(invoiceNumber, CONSTANT_NUMBER.TEN)
            : invoiceNumber}
        </Stack>
      </TableCell>

      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell className="cursor-pointer" onClick={onClickSelectedRow} align="left">
          {createDate ? fDate(createDate) : ''}
        </TableCell>
      )}

      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell className="cursor-pointer" onClick={onClickSelectedRow} align="left">
          {dueDate ? fDate(dueDate) : ''}
        </TableCell>
      )}

      <TableCell
        className="cursor-pointer"
        onClick={onClickSelectedRow}
        align={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'right' : 'left'}
        sx={{ padding: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '16px 4px 16px 4px' : '16px' }}
      >
        <Stack>
          {totalAmount ? concatenateSelectedCurrency(currency, totalAmount) : '-'}

          {width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
            <div>
              <Label
                className="cursor-pointer"
                variant={theme.palette.mode === 'light' ? 'filled' : 'ghost'}
                color={
                  (status === INVOICE_STATUS.INVOICE_PAID && 'success') ||
                  (status === INVOICE_STATUS.INVOICE_UNPAID && 'warning') ||
                  (status === INVOICE_STATUS.INVOICE_OVERDUE && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'capitalize' }}
              >
                {status}
              </Label>
            </div>
          )}
        </Stack>
      </TableCell>

      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell className="cursor-pointer" onClick={onClickSelectedRow} align="left">
          <Label
            className="cursor-pointer"
            variant={theme.palette.mode === 'light' ? 'filled' : 'ghost'}
            color={
              (status === INVOICE_STATUS.INVOICE_PAID && 'success') ||
              (status === INVOICE_STATUS.INVOICE_UNPAID && 'warning') ||
              (status === INVOICE_STATUS.INVOICE_OVERDUE && 'error') ||
              'default'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </TableCell>
      )}

      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    onViewRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:eye-fill'} />
                  View
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    onDuplicateRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'heroicons-solid:document-duplicate'} />
                  Duplicate
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
                <Divider className="my-2" />
                <MenuItem
                  onClick={() => {
                    onDeleteRow();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
              </>
            }
          />
        </TableCell>
      )}
    </TableRow>
  );
}
