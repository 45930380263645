import React from 'react';

function ArrowDownBold() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 5L8.23556 10.8795C8.20464 10.9177 8.16792 10.948 8.1275 10.9687C8.08708 10.9894 8.04376 11 8 11C7.95624 11 7.91292 10.9894 7.8725 10.9687C7.83208 10.948 7.79536 10.9177 7.76444 10.8795L3 5"
        fill="#464646"
      />
      <path
        d="M13 5L8.23556 10.8795C8.20464 10.9177 8.16792 10.948 8.1275 10.9687C8.08708 10.9894 8.04376 11 8 11C7.95624 11 7.91292 10.9894 7.8725 10.9687C7.83208 10.948 7.79536 10.9177 7.76444 10.8795L3 5H13Z"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ArrowDownBold;
