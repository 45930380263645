import React, { useState } from 'react';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import DownloadInvoiceAsPDF from '../../components/common/DownloadInvoiceAsPDF';
import BaseText from '../ui/typography/BaseText';
import CashInHandIcon from '../../images/icons/cash-in-hand-icon.svg';
import { fDate } from '../../utils/formatTime';
import AddPaymentModal from '../publicInvoice/AddPaymentModal';
import PaidPaymentModal from './PaidPaymentModal';
import { EXTERNAL_PAYMENT_LINKS, INVOICE_STATUS, PAYMENT_STATUS_INVOICE } from '../../constants/enum';
import PayMeIcon from '../../images/icons/pay-me-icon.svg';
import PayPalIcon from '../../images/icons/pay-pal-icon.svg';
import XMSText from '../ui/typography/XMSText';
import { concatenateSelectedCurrency, convertDateToDDMMYYYY, isObjectEmpty } from '../../helper/helpers';
import XSText from '../ui/typography/XSText';

export default function PublicInvoiceDetails({ getInvoiceDetails, publicInvoiceDetails }) {
  const [addPayment, setAddPayment] = useState(false);
  const [paidPayment, setPaidPayment] = useState(false);

  const closePaidPaymentModal = () => {
    setPaidPayment(false);
  };

  const openPaidPaymentModal = () => {
    setPaidPayment(true);
  };

  const closeAddPaymentModal = () => {
    setAddPayment(false);
  };

  const lastPaymentRecorded = getInvoiceDetails?.payments?.slice(-1)[0];

  const handleToAddPayment = () => {
    setAddPayment(true);
  };
  return (
    <div className="w-full bg-white  public-invoice-details-container">
      <div className="whitespace-pre-line py-6 px-8">
        <MDSubtitleText
          fontWeight="text-bold"
          title={`Invoice #${getInvoiceDetails?.invoiceNumber} \n from ${getInvoiceDetails?.companyDetails.companyName}`}
        />
        {getInvoiceDetails?.paymentStatus === PAYMENT_STATUS_INVOICE.PAID ||
        getInvoiceDetails?.paymentStatus === PAYMENT_STATUS_INVOICE.PARTIAL_PAID ? (
          <BaseText
            textColor="text-green-500"
            className="public-invoice-due-date-detail mt-2 mb-10"
            title={`was ${getInvoiceDetails?.paymentStatus === 'paid' ? 'fully paid' : 'partially paid'} on ${fDate(
              lastPaymentRecorded?.paidDate ? lastPaymentRecorded?.paidDate : getInvoiceDetails?.dueDate
            )}`}
          />
        ) : (
          <BaseText
            textColor="text-coral-500"
            className="public-invoice-due-date-detail mt-2 mb-10"
            title={`is waiting for payment due \n${fDate(getInvoiceDetails?.dueDate)}`}
          />
        )}

        {getInvoiceDetails?.payments?.length > 0 && getInvoiceDetails?.status !== INVOICE_STATUS.INVOICE_DRAFT && (
          <>
            {getInvoiceDetails?.payments.map((payment) => (
              <>
                <div className="bg-customBg-mainBg pt-2 pb-4 px-4  rounded-lg mb-2 mt-2">
                  <div className="flex justify-between items-center">
                    <XMSText className="my-2" title={payment?.paidDate && convertDateToDDMMYYYY(payment?.paidDate)} />
                  </div>
                  <XSText
                    className="mb-2"
                    textColor="text-coral-500"
                    title={
                      payment?.paidAmount &&
                      concatenateSelectedCurrency(getInvoiceDetails?.currency, payment?.paidAmount)
                    }
                  />
                  <XMSText title={payment?.paymentType} />
                </div>
              </>
            ))}
          </>
        )}

        {getInvoiceDetails?.payment?.length > 0 && (
          <div className="mt-4">
            <XSText
              textColor="text-gray-450"
              title={`${concatenateSelectedCurrency(
                getInvoiceDetails?.currency,
                getInvoiceDetails?.pendingAmount
              )} remaining`}
            />
          </div>
        )}
        <div className="flex flex-col mt-10">
          {getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLink &&
            getInvoiceDetails?.paymentStatus !== PAYMENT_STATUS_INVOICE.PAID && (
              <PrimaryButton
                urlLink={getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLink}
                target="_blank"
                id="custom-payment-link"
                type="small"
                className="public-invoice-download-button mb-2 w-full"
                color="text-white"
                fontSize="text-lg"
                fontWeight="text-bold"
                caption={
                  <div className="flex justify-center gap-2.5 text-medium items-center">
                    {getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLabel}
                  </div>
                }
              />
            )}
          {!isObjectEmpty(getInvoiceDetails?.paymentDetails?.paypalDetails) &&
            getInvoiceDetails?.paymentDetails?.paypalDetails?.isSelected === true &&
            getInvoiceDetails?.paymentStatus !== PAYMENT_STATUS_INVOICE.PAID && (
              <PrimaryButton
                id="paypal-pay"
                urlLink={`${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${
                  getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink
                }${
                  getInvoiceDetails?.totalAmount
                    ? `/${getInvoiceDetails?.totalAmount}${getInvoiceDetails?.currency?.name}`
                    : ''
                }`}
                target="_blank"
                type="small"
                className="public-invoice-download-button mb-2 w-full"
                color="text-white"
                fontSize="text-lg"
                fontWeight="text-bold"
                caption={
                  <div className="flex justify-center gap-2.5 text-medium items-center">
                    <img src={PayPalIcon} alt="downloadIcon" />
                    Pay with PayPal
                  </div>
                }
              />
            )}

          {!isObjectEmpty(getInvoiceDetails?.paymentDetails?.hsbcDetails) &&
            getInvoiceDetails?.paymentDetails?.hsbcDetails?.isSelected === true &&
            getInvoiceDetails?.paymentStatus !== PAYMENT_STATUS_INVOICE.PAID && (
              <PrimaryButton
                urlLink={`${EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}${getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink}`}
                target="_blank"
                id="payme-pay"
                type="small"
                className="public-invoice-download-button mb-2 w-full"
                color="text-white"
                fontSize="text-lg"
                fontWeight="text-bold"
                caption={
                  <div className="flex justify-center gap-2.5 text-medium items-center">
                    <img src={PayMeIcon} alt="downloadIcon" />
                    Pay with PayMe
                  </div>
                }
              />
            )}

          {(getInvoiceDetails?.payments.length === 0 || getInvoiceDetails?.paymentStatus === 'partialpaid') && (
            <PrimaryButton
              type="small"
              id="already-paid-button"
              className="public-invoice-download-button mb-2 w-full"
              bgColor="bg-white"
              color="text-coral-500"
              fontSize="text-lg"
              fontWeight="text-bold"
              caption={
                <div className="flex justify-center gap-2.5 text-medium items-center">
                  <img src={CashInHandIcon} alt="downloadIcon" />
                  I’ve already paid
                </div>
              }
              onClick={handleToAddPayment}
            />
          )}

          <DownloadInvoiceAsPDF
            id="download-button"
            caption="Download PDF"
            className="public-download-link"
            getInvoiceDetails={getInvoiceDetails}
          />
        </div>
        <AddPaymentModal
          isPublic={true}
          getInvoiceDetails={getInvoiceDetails}
          openDynamicModal={addPayment}
          closeDynamicModal={closeAddPaymentModal}
          openPaidPaymentModal={openPaidPaymentModal}
          publicInvoiceDetails={publicInvoiceDetails}
        />
        <PaidPaymentModal openPaidDynamicModal={paidPayment} closePaidPaymentModal={closePaidPaymentModal} />
      </div>
      {getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLink && (
        <div className="custom-payment-note">
          Please note that any external link included in this invoice is provided directly by the sender. Statrys does
          not endorse and is not responsible for the content, accuracy, or function of these links.
        </div>
      )}
    </div>
  );
}
