import React from 'react';

function Invoicing() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#097D75" />
      <g clip-path="url(#clip0_5423_7157)">
        <path
          d="M29 11.1836L29 10.92C29 9.81543 28.1046 8.92 27 8.92L13 8.92C11.8954 8.92 11 9.81543 11 10.92L11 29.5C11 30.6046 11.8954 31.5 13 31.5L27 31.5C28.1046 31.5 29 30.6046 29 29.5V29.5"
          stroke="white"
          stroke-width="1.56"
          stroke-linecap="round"
        />
        <path
          d="M27.8796 20.7773V20.7773C27.1003 20.7773 26.4686 20.1456 26.4686 19.3664V19.185C26.4686 18.6162 26.8008 18.1128 27.1765 17.6857C27.3799 17.4545 27.5454 17.1901 27.6648 16.9018C27.9025 16.3279 27.9442 15.6916 27.7834 15.0916C27.6226 14.4915 27.2684 13.9613 26.7756 13.5831C26.2827 13.205 25.6789 13 25.0577 13C24.4365 13 23.8327 13.205 23.3399 13.5831C22.8471 13.9613 22.4928 14.4915 22.332 15.0916C22.1713 15.6916 22.213 16.3279 22.4507 16.9018C22.5701 17.1901 22.7356 17.4545 22.9389 17.6857C23.3146 18.1128 23.6468 18.6162 23.6468 19.185V19.3664C23.6468 20.1456 23.0151 20.7773 22.2359 20.7773V20.7773C21.4875 20.7773 20.7698 21.0746 20.2406 21.6038C19.7114 22.133 19.4141 22.8508 19.4141 23.5992H30.7014C30.7014 22.8508 30.4041 22.133 29.8749 21.6038C29.3457 21.0746 28.628 20.7773 27.8796 20.7773Z"
          stroke="white"
          stroke-width="1.45313"
          stroke-linecap="round"
        />
        <path
          d="M19.4141 24.1777L19.7667 25.5885C19.9741 26.4178 20.7192 26.9997 21.5741 26.9997H28.5413C29.3962 26.9997 30.1414 26.4178 30.3487 25.5885L30.7014 24.1777"
          stroke="white"
          stroke-width="1.45313"
          stroke-linecap="round"
        />
        <path d="M19.5 12.7529H14" stroke="white" stroke-width="1.56" stroke-linecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_5423_7157">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Invoicing;
