import React, { useState, useEffect } from 'react';
import Contact from '../../components/contact/Contact';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Layout from '../../components/layout';
import Page from '../../components/Page';
import { API_CODE, PAGE_TITLE, RESPONSIVENESS_BREAKPOINT_VALUE } from '../../constants/enum';
import IconPlus from '../../images/icons/plus-icon.svg';
import { INVOICE_DASHBOARD } from '../../routes/paths';
import '../../styles/pages/contact.scss';
import { connect } from 'react-redux';
import * as contactActions from '../../components/contact/reduxStore/action';
import StatrysLoader from '../../components/ui/loaders/StatrysLoader';
import ContactListBanner from '../../components/contact/contactBanners/ContactListBanner';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function ContactListing({ contactList }) {
  const [contact, setContact] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();

  const contactAllList = async () => {
    const response = await contactList();
    if (response?.status === API_CODE.STATUS_200) {
      setContact(response?.data?.data);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    contactAllList();
  }, []);

  const allList = contact;
  return (
    <Layout>
      <Page title="Contact">
        <HeaderBreadcrumbs
          subtitleClass={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'mb-0' : 'mb-2'}
          heading={PAGE_TITLE.CONTACT}
          links={
            width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
              ? [{ name: PAGE_TITLE.DASHBOARD, href: INVOICE_DASHBOARD }, { name: PAGE_TITLE.CONTACT }]
              : [{ name: '' }]
          }
        />
        {loading ? (
          <StatrysLoader />
        ) : (
          <>
            {allList.length > 0 && (
              <Contact allList={allList} loading={loading} contactAllList={contactAllList} error={error} />
            )}
          </>
        )}
        {!loading && allList.length === 0 && (
          <div className="mt-12">
            <ContactListBanner />
          </div>
        )}
      </Page>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    contactList: () => dispatch(contactActions.allContactList())
  };
};

export default connect(null, mapDispatchToProps)(ContactListing);
