import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InvoicingIcon from '../../images/icons/header/invoicing-icon.svg';
import { AppBar, Menu, Paper, Tabs, Toolbar, Typography } from '@mui/material';
import StatrysLogo from '../../images/icons/statrysLogo.svg';
import { useState } from 'react';
import DashboardIcon from '../../images/icons/sideBar/pie-line-dashboard.svg';
import ActiveDashboardIcon from '../../images/icons/sideBar/pie-line-dashboard-active.svg';
import InvoiceIcon from '../../images/icons/sideBar/invoice-file-icon.svg';
import ActiveInvoiceIcon from '../../images/icons/sideBar/invoice-file-icon-active.svg';
import CashPaymentIcon from '../../images/icons/sideBar/cash-payment-coin.svg';
import ActiveCashPaymentIcon from '../../images/icons/sideBar/cash-payment-coin-active.svg';
import ManContactBookIcon from '../../images/icons/sideBar/man-contact-book.svg';
import ActiveManContactBookIcon from '../../images/icons/sideBar/man-contact-book-active.svg';
import { IMPORTING_INVOICE, INVOICE_DASHBOARD, INVOICE_LIST, PATH_PAGE } from '../../routes/paths';
import BaseText from '../ui/typography/BaseText';
import { getUser, logOut } from '../authService/auth';
import MenuIcon from '../../images/icons/header/mobile-hamburger-menu.svg';
import FooterLogo from '../../images/icons/footer-statrys-logo.svg';
import StatrysSupport from '../common/statrysSupport/StatrysSupport';
import { useEffect } from 'react';
import { MUI_COLORS, STATUS, SVG_TYPES } from '../../constants/enum';
import { Link, navigate } from 'gatsby';
import MSText from '../ui/typography/MSText';
import { getSecondLastPathName, getURLFirstPathName } from '../../helper/helpers';
import { useLocation } from '@reach/router';
import { getSvgIcon } from '../../helper/svgHelper';
import AccountSwitcherMenu from './AccountSwitcherMenu';
import Avatar from '../ui/Avatar';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import * as accountSettingActions from '../../components/accountSetting/reduxStore/action';
import { connect } from 'react-redux';
import NavigationDropDownLeft from './NavigationDropDownLeft';

const navigation = [
  {
    id: 1,
    pageName: 'Dashboard',
    icon: DashboardIcon,
    activeIcon: ActiveDashboardIcon,
    to: INVOICE_DASHBOARD
  },
  {
    id: 2,
    pageName: 'Invoices',
    icon: InvoiceIcon,
    activeIcon: ActiveInvoiceIcon,
    to: INVOICE_LIST
  },
  {
    id: 3,
    pageName: 'Expenses',
    icon: CashPaymentIcon,
    activeIcon: ActiveCashPaymentIcon,
    to: PATH_PAGE.pay
  },
  {
    id: 4,
    pageName: 'Contacts',
    icon: ManContactBookIcon,
    activeIcon: ActiveManContactBookIcon,
    to: PATH_PAGE.contact
  }
];

const profileOptions = [
  {
    pageName: 'Profile',
    icon: getSvgIcon(SVG_TYPES.PROFILE),
    to: PATH_PAGE.profile
  },
  {
    pageName: 'Account Settings',
    icon: getSvgIcon(SVG_TYPES.SETTING),
    to: PATH_PAGE.accountSettings
  },
  {
    pageName: 'Logout',
    icon: getSvgIcon(SVG_TYPES.LOGOUT),
    to: ''
  }
];

function MobileNavigation({ children, getProfile, getCompanyInfo }) {
  const [value, setValue] = useState('recents');
  const [open, setOpen] = useState(false);
  const ref = React.useRef(null);
  const location = useLocation();
  const user = getUser();
  const [profileData, setProfileData] = useState({});
  const [companyFormdata, setCompanyFormdata] = useState();
  const [allCompanies, setAllCompanies] = useState([]);

  const handleActiveTab = (urlLink) => {
    const urlFirstPathName = getURLFirstPathName(location?.pathname);
    const linkFirstPath = getURLFirstPathName(urlLink);
    if (`/${urlFirstPathName}` === PATH_PAGE.company) {
      const urlSecondPathName = getSecondLastPathName(location?.pathname);
      const linkSecondPath = getSecondLastPathName(urlLink);
      if (urlSecondPathName === linkSecondPath) {
        return true;
      } else {
        return false;
      }
    } else {
      if (urlFirstPathName === linkFirstPath) {
        return true;
      } else if (
        (`/${urlFirstPathName}` === PATH_PAGE.viewIncomingInvoices || `/${urlFirstPathName}` === IMPORTING_INVOICE) &&
        urlLink === PATH_PAGE.pay
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const navigationMenu = navigation;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  const firstName = profileData?.firstName ? profileData?.firstName : user?.email;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
    setProfileData(getProfile);
    setCompanyFormdata(getCompanyInfo);
  }, [getProfile, getCompanyInfo]);

  return (
    <div className="h-screen" ref={ref}>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: 'white',
          boxShadow: 'none',
          '& .MuiToolbar-root': {
            paddingRight: '8px',
            paddingLeft: '8px'
          }
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            <div className="flex">
              <div className="dropdown flex items-center gap-2">
                <img src={InvoicingIcon} alt="invoice management" />
                <div>
                  <BaseText fontSize="text-xl" fontWeight="text-bold" title={'Invoicing'} />
                </div>
                {process.env.SSO_ON === STATUS.TRUE && (
                  <>
                    {getSvgIcon(SVG_TYPES.ARROW_DOWN_BOLD)}
                    <NavigationDropDownLeft
                      menuItems={profileOptions}
                      isNavigation={true}
                      companyList={allCompanies}
                      needSeparator={true}
                      separtorPositions={[1]}
                      className={'dropdown-content-container-left'}
                    ></NavigationDropDownLeft>
                  </>
                )}
              </div>
            </div>
          </Typography>
          <div className="flex gap-8">
            <div className="flex items-center item-menu-dropdown dropdown">
              <Menu
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                PaperProps={{}}
              >
                <div className="sticky top-0 bg-white h-16">
                  <div className="flex flex-row justify-between ml-2 items-center pt-4">
                    <Link className="flex hover:no-underline">
                      <img src={StatrysLogo} alt="Statrys Logo" />
                    </Link>
                  </div>
                </div>
                {profileOptions?.map((menuItem, index) => {
                  return (
                    <div className="mt-6 expand-section ml-2" key={index}>
                      <div>
                        <Link
                          className="hover:no-underline flex flex-row cursor-pointer mobile-menu-items"
                          to={menuItem?.to}
                          onClick={menuItem?.pageName === 'Logout' && logOut}
                        >
                          <div className="py-2 pl-2">{menuItem?.icon}</div>
                          <BaseText
                            title={menuItem?.pageName}
                            fontWeight="text-semibold"
                            textColor="text-gray-700"
                            className="px-2 py-2"
                          />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Menu>
              <div className="flex flex-row gap-2 items-center cursor-pointer p-2" onClick={() => setOpen(true)}>
                <Avatar
                  avatarImage={companyFormdata?.logoPublic}
                  avatarName={firstName ? firstName?.charAt(0) : user?.email?.charAt(0)}
                  avatarStyling="w-8 h-8"
                />
              </div>
              {/* <AccountSwitcherMenu menuItems={profileOptions} /> */}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <main id="main-container" className="py-14 mt-4 px-2 md:mb-0 mb-20">
        {children}
      </main>
      <footer className="md:pb-5 sm:pb-20">
        <div className="flex justify-center">
          <img src={FooterLogo} alt={FooterLogo} />
        </div>
        <StatrysSupport />
      </footer>
      <Paper
        sx={{
          position: 'fixed',
          bottom: -6,
          left: 0,
          right: 0,
          zIndex: 12,
          borderTopLeftRadius: '32px',
          borderTopRightRadius: '32px',
          paddingTop: '14px',
          paddingBottom: '14px'
        }}
        elevation={3}
      >
        <BottomNavigation
          onChange={handleChange}
          value={value}
          showLabels
          sx={{
            '&.MuiBottomNavigation-root': {
              marginLeft: '20px',
              marginRight: '20px'
            }
          }}
        >
          {navigationMenu?.map((navigate) => (
            <BottomNavigationAction
              sx={{
                '&.MuiBottomNavigationAction-root': {
                  padding: '0px'
                },
                '& .Mui-selected': {
                  backgroundColor: 'transparent',
                  color: 'inherit'
                }
              }}
              label={
                <MSText
                  textColor={`${handleActiveTab(navigate?.to) && 'text-gray-450'}`}
                  fontWeight={`${handleActiveTab(navigate?.to) && 'text-bold'}`}
                  className="mt-2"
                  title={navigate?.pageName}
                />
              }
              value={navigate?.to}
              icon={
                <img
                  src={handleActiveTab(navigate?.to) ? navigate?.activeIcon : navigate?.icon}
                  width={24}
                  height={24}
                  alt={navigate?.pageName}
                />
              }
            />
          ))}
        </BottomNavigation>
      </Paper>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    getProfile: state?.userProfieReducer?.getUserProfileDetails?.data,
    getCompanyInfo: state?.accountSettings?.getCompanyDetails?.data?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation);
