import React from 'react';

function BusinessAccount() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#E15741" />
      <path
        d="M10.75 23.9601V15.9658C10.75 15.2189 11.1662 14.5342 11.8294 14.1903L21.3294 9.2644C22.6607 8.57409 24.25 9.54027 24.25 11.0399V19.0342C24.25 19.7811 23.8338 20.4658 23.1706 20.8097L13.6706 25.7356C12.3393 26.4259 10.75 25.4597 10.75 23.9601Z"
        stroke="white"
        stroke-width="1.5625"
      />
      <path
        d="M24.625 13.75H27.25C28.3546 13.75 29.25 14.6454 29.25 15.75V24.25C29.25 25.3546 28.3546 26.25 27.25 26.25H12.75C11.6454 26.25 10.75 25.3546 10.75 24.25V20"
        stroke="white"
        stroke-width="1.5625"
      />
      <path
        d="M29.25 20V24.9503C29.25 25.7406 28.7845 26.4569 28.0623 26.7779L18.5623 31.0001C17.2397 31.5879 15.75 30.6198 15.75 29.1725V27"
        stroke="white"
        stroke-width="1.5625"
      />
    </svg>
  );
}

export default BusinessAccount;
