import React from 'react';
import BaseText from '../ui/typography/BaseText';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import '../../styles/pages/payment-review.scss';
import MSText from '../ui/typography/MSText';
import { INVOICE_STATUS, MUI_COLORS } from '../../constants/enum';
import { addCommas } from '../../helper/helpers';

ChartJS.register(ArcElement, Tooltip, Legend);

function AccountReceivable({ totalAmountCurrency, currency }) {
  const findTotalCharacter = (amount) => {
    if (amount) {
      const countOfChar = amount;
      if (countOfChar.length > 14) {
        return true;
      } else {
        return false;
      }
    }
  };
  const data = {
    labels: [INVOICE_STATUS.INVOICE_PAID, INVOICE_STATUS.INVOICE_UNPAID, INVOICE_STATUS.INVOICE_OVERDUE],
    datasets: [
      {
        label: 'Invoice Payment',
        data: [
          totalAmountCurrency?.Paid?.toFixed(2),
          totalAmountCurrency?.Unpaid?.toFixed(2),
          totalAmountCurrency?.Overdue?.toFixed(2)
        ],
        backgroundColor: [MUI_COLORS.GREEN, MUI_COLORS.SUNGLOW, MUI_COLORS.SUNSET_ORANGE],
        borderColor: [MUI_COLORS.GREEN, MUI_COLORS.SUNGLOW, MUI_COLORS.SUNSET_ORANGE],
        borderWidth: 1,
        borderRadius: 40,
        spacing: 4,
        cutout: '90%'
      }
    ],
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 30
          }
        }
      }
    }
  };

  const outStandingValue = Number(totalAmountCurrency?.Unpaid) + Number(totalAmountCurrency?.Overdue);

  return (
    <div className="bg-white rounded-2xl px-6 pt-6 account-recieveable-main md:ml-6">
      <div className="md:mb-10 mb-3">
        <BaseText fontWeight="text-bold" fontSize="text-lg" title="Accounts receivable" />
      </div>
      <div className="doughnut-container relative">
        <div className="absolute">
          <Doughnut data={data} options={data.options} />
        </div>
        {(totalAmountCurrency?.Paid || totalAmountCurrency?.Unpaid) && (
          <div className="absolute inside-chart-text-container">
            <MSText className="text-align" fontWeight="text-bold" textColor="text-gray-450" title="Paid" />
            <BaseText
              className={`${
                findTotalCharacter(addCommas(outStandingValue?.toFixed(2)))
                  ? 'account-chart-small-text'
                  : 'account-chart-inside-text'
              } account-inline-flex text-center mb-2`}
              textColor="text-green-500"
              fontWeight="text-bold"
              title={
                <>
                  <span className="account-chart-inside-currency">
                    {currency?.name ? currency?.name : currency?.symbol}
                  </span>{' '}
                  {addCommas(totalAmountCurrency?.Paid?.toFixed(2))}
                </>
              }
            />
            <MSText fontWeight="text-bold" className="text-align" textColor="text-gray-450" title="Outstanding" />
            <BaseText
              className={`${
                findTotalCharacter(addCommas(outStandingValue?.toFixed(2)))
                  ? 'account-chart-small-text'
                  : 'account-chart-inside-text'
              } account-inline-flex text-center`}
              textColor="text-yellow-500"
              fontWeight="text-bold"
              title={
                <>
                  <span className="account-chart-inside-currency">
                    {currency?.name ? currency?.name : currency?.symbol}
                  </span>{' '}
                  {addCommas(outStandingValue?.toFixed(2))}
                </>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AccountReceivable;
