import React from 'react';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Layout from '../../components/layout';
import Page from '../../components/Page';
import { PAGE_TITLE, RESPONSIVENESS_BREAKPOINT_VALUE, STRING_MAX_CHAR } from '../../constants/enum';
import { CONTACT, INVOICE_DASHBOARD, PATH_PAGE } from '../../routes/paths';
import ViewContact from '../../components/contact/ViewContact';
import ViewContactList from '../../components/contact/ViewContactList';
import '../../styles/pages/contact.scss';
import { connect } from 'react-redux';
import { stringTruncate } from '../../helper/helpers';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function View({ getContactDetails, getSupplierDetails }) {
  const { width } = useWindowDimensions();

  return (
    <Layout>
      <Page title="Contact">
        <HeaderBreadcrumbs
          subtitleClass={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'mb-0' : 'mb-2'}
          heading={PAGE_TITLE.CONTACT}
          links={
            width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
              ? [
                  { name: PAGE_TITLE.DASHBOARD, href: INVOICE_DASHBOARD },
                  { name: PAGE_TITLE.CONTACT, href: PATH_PAGE.contact },
                  {
                    name: `${
                      getContactDetails?.companyName
                        ? stringTruncate(getContactDetails?.companyName, STRING_MAX_CHAR.TEN)
                        : stringTruncate(getSupplierDetails?.companyName, STRING_MAX_CHAR.TEN)
                    }`
                  }
                ]
              : [{ name: '' }]
          }
        />
        <div className="flex md:flex-row flex-col mt-10">
          <div>
            <ViewContact />
          </div>
          <div className="w-full">
            <ViewContactList />
          </div>
        </div>
      </Page>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    getContactDetails: state?.contactReducer?.viewContactDetails?.data,
    getSupplierDetails: state?.supplierReducer?.supplierDetails?.data?.data
  };
};

export default connect(mapStateToProps, null)(View);
