import React, { useState, useEffect, useRef } from 'react';
import Seo from '../../components/seo';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_PAGE } from '../../routes/paths';
import '../../styles/pages/view-invoice.scss';
import MSText from '../../components/ui/typography/MSText';
import Layout from '../../components/layout';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import IconEdit from '../../images/icons/customSvg/EditBase';
import CheckMark from '../../images/icons/customSvg/CheckMark';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as invoiceActions from '../../components/invoice/reduxStore/action';
import * as commonActions from '../../components/commonReduxStore/reduxStore/action';
import { useForm } from 'react-hook-form';
import copy from 'copy-to-clipboard';
import DynamicModal from '../../components/ui/modal/DynamicModal';
import MDSubtitleText from '../../components/ui/typography/MDSubtitleText';
import CopyClipboard from '../../images/icons/customSvg/CopyClipboard';
import TimeClockIcon from '../../images/icons/time-clock.svg';
import {
  PAGE_TITLE,
  REGEX,
  MUI_COLORS,
  API_CODE,
  INVOICE_STATUS,
  SOCIAL_SHARE_LINK,
  STRING_MAX_CHAR,
  RESPONSIVENESS_BREAKPOINT_VALUE
} from '../../constants/enum';
import { defaultValidationMessage, stringTruncate } from '../../helper/helpers';
import ValidationMessage from '../../components/ui/ValidationMessage';
import TextInput from '../../components/ui/inputs/TextInput';
import MultiTextInput from '../../components/ui/inputs/MultiTextInput';
import XSText from '../../components/ui/typography/XSText';
import { useLocation } from '@reach/router';
import DownloadInvoiceAsPDF from '../../components/common/DownloadInvoiceAsPDF';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import SuccessIcon from '../../images/icons/success-icon.svg';
import Image from '../../components/Image';
import Snackbar from '../../components/Snackbar';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Telegram } from '@mui/icons-material';
import BaseText from '../../components/ui/typography/BaseText';
import PreviewImage from '../../images/graphics/invoice.png';
import Iconify from '../../components/Iconify';
import { navigate } from 'gatsby';
import { Link } from '@mui/material';
import * as contactActions from '../../components/contact/reduxStore/action';
import AddPaymentModal from '../../components/publicInvoice/AddPaymentModal';
import PaymentDetails from '../../components/invoiceCommon/PaymentDetails';
import PublicViewInvoice from '../../components/common/PublicViewInvoice';
import StatrysLoader from '../../components/ui/loaders/StatrysLoader';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const View = ({
  getInvoiceDetails,
  editInvoice,
  getUserProfile,
  sendEmailInvoice,
  createDuplicateInvoice,
  editContact,
  invoiceCount,
  checkSendEmail
}) => {
  const [markedAsPaid, setMarkedAsPaid] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [sendInvoice, setSendInvoice] = useState(false);
  const [sendByMail, setSendByMail] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState('');
  const [statusOfInvoice, setStatusOfInvoice] = useState('');
  const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);
  const [invoiceDetailsByID, setInvoiceDetailsByID] = useState({});
  const [userProfileData, setUserProfileData] = useState('');
  const [error, setError] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isSendInvoiceLoading, setIsSendInvoiceLoading] = useState(false);
  const [onbuttonLoading, setOnButtonloading] = useState(false);
  const [addPayment, setAddPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [showAlreadySharedModal, setShowAlreadySharedModal] = useState(false);
  const location = useLocation();
  const { width } = useWindowDimensions();

  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });

  const closeSnackbar = () => {
    setIsSnackBarOpen(false);
  };

  useEffect(() => {
    handleInvoiceDetailsbyID();
    getProfileDetailsHandler();
  }, []);
  const handleInvoiceDetailsbyID = async () => {
    const query = new URLSearchParams(location?.search);
    const invoiceId = query.get('id');
    setInvoiceLink(`${process.env.SITE_URL}/public?id=${invoiceId}`);
    const getInvoiceDetailsByIDResponse = await getInvoiceDetails(invoiceId);
    if (getInvoiceDetailsByIDResponse?.status === API_CODE?.STATUS_200) {
      setInvoiceDetailsByID(getInvoiceDetailsByIDResponse?.data?.data);
      if (location?.state?.openShareModal) {
        handleOpenShareModal(getInvoiceDetailsByIDResponse?.data?.data?._id);
      }
      getStatusFromView(getInvoiceDetailsByIDResponse?.data?.data);
      setIsLoading(false);
    }
  };

  const getProfileDetailsHandler = async () => {
    const getUserProfileDetail = await getUserProfile();
    if (getUserProfileDetail?.status === API_CODE?.STATUS_200) {
      setUserProfileData(getUserProfileDetail?.data?.data);
    } else {
      setError(true);
    }
  };

  const closeAddPaymentModal = () => {
    setAddPayment(false);
  };

  const params = new URLSearchParams(location?.search);
  const id = params.get('id');

  const closeDynamicModal = () => {
    setSendInvoice(false);
  };
  const copyInvoiceLink = () => {
    setIsSnackBarOpen(true);
    setSuccessMessage({
      ...successMessage,
      title: `Success`,
      content: `Link Copied sucessfully!`
    });
    copy(invoiceLink);
  };

  const sendInvoiceByMail = () => {
    setSendInvoice(false);
    setSendByMail(true);
  };

  const closeSendByMailDynamicModal = () => {
    setSendByMail(false);
  };

  const sendInvoiceWithMail = async (data) => {
    const setEmailResponse = await sendEmailInvoice(data);
    if (setEmailResponse?.status === API_CODE?.STATUS_200) {
      setUserProfileData(setEmailResponse?.data?.data);
      setSendByMail(false);
      setIsSnackBarOpen(true);
      setSuccessMessage({
        ...successMessage,
        title: setEmailResponse?.data?.message,
        content: `Email send sucessfully!`
      });
    } else {
      setError(true);
    }
  };
  const substringToRemove = 'statrys';
  const regex = new RegExp(substringToRemove, 'g');

  const onSubmit = async (data) => {
    setIsSendInvoiceLoading(true);
    const messageContent = data.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
    const requestPayload = {
      subject: data?.subject.replace(regex, ''),
      invoiceNumber: invoiceDetailsByID?.invoiceNumber.replace(regex, ''),
      companyName: invoiceDetailsByID?.companyDetails?.companyName.replace(regex, ''),
      publicURL: invoiceLink,
      message: messageContent.replace(regex, ''),
      toEmail: data?.email,
      invoiceId: id
    };
    await sendInvoiceWithMail(requestPayload);
    setIsSendInvoiceLoading(false);
    getProfileDetailsHandler();
    !invoiceDetailsByID?.contact?.email && updateEmailOfContactHandler(data?.email);
  };

  const updateEmailOfContactHandler = async (emailOfContact) => {
    const request = {
      email: emailOfContact,
      contactId: invoiceDetailsByID?.contact?.contactId
    };
    const editContactResponse = await editContact(request);
    if (editContactResponse?.status === API_CODE.STATUS_200) {
      const payload = {
        contact: { ...editContactResponse?.data?.data, contactId: editContactResponse?.data?.data?._id }
      };
      updatingInvoiceHandler(payload);
    }
  };

  const onMarkAsPaid = async (id) => {
    setAddPayment(true);
  };

  const updateStatusOnPayment = (status) => {
    const payload = {
      status: status === INVOICE_STATUS.INVOICE_PAID ? INVOICE_STATUS.INVOICE_PAID : INVOICE_STATUS.INVOICE_PARTIAL_PAID
    };
    updatingInvoiceHandler(payload);
  };

  const updatingInvoiceHandler = async (otherPayload) => {
    const requestPayload = {
      invoiceId: id,
      ...otherPayload
    };
    const editInvoiceResponse = await editInvoice(requestPayload);
    if (editInvoiceResponse?.status === API_CODE.STATUS_200) {
      setMarkedAsPaid(true);
      await handleInvoiceDetailsbyID();
    }
    setMarkAsPaidLoading(false);
  };

  const invoiceCountHandler = async (invoiceData) => {
    const payload = {
      contactId: invoiceData?.contact?.contactId
    };
    const invoiceCountResponse = await invoiceCount(payload);
    if (invoiceCountResponse?.status === API_CODE.STATUS_200) {
      createDuplicateInvoiceHandler(invoiceData, invoiceCountResponse?.data?.data);
    }
  };

  const duplicateInvoiceHandler = async () => {
    setOnButtonloading(true);
    const getInvoiceResponse = await getInvoiceDetails(id);
    if (getInvoiceResponse?.status === API_CODE.STATUS_200) {
      invoiceCountHandler(getInvoiceResponse?.data?.data);
    }
  };

  const createDuplicateInvoiceHandler = async (invoiceResponse, invoiceCount) => {
    const requestPayload = {
      companyDetails: invoiceResponse?.companyDetails,
      contact: invoiceResponse?.contact,
      services: invoiceResponse?.services,
      goods: invoiceResponse?.goods,
      items: invoiceResponse?.items,
      taxWithPrice: invoiceResponse?.taxWithPrice,
      invoiceDiscount: invoiceResponse?.invoiceDiscount,
      currency: invoiceResponse?.currency,

      notes: invoiceResponse?.notes,
      invoiceNumber: `INV-${invoiceCount}`,
      dueDate: invoiceResponse?.dueDate ? invoiceResponse?.dueDate : '',
      createDate: invoiceResponse?.createDate,
      otherReferences: invoiceResponse?.otherReferences,
      totalAmount: invoiceResponse?.totalAmount,
      subtotal: invoiceResponse?.subtotal,
      logoPublic: invoiceResponse?.logoPublic,
      paymentDetails: invoiceResponse?.paymentDetails,
      status: INVOICE_STATUS.INVOICE_DRAFT,
      userId: invoiceResponse?.userId
    };
    const getDuplicateInvoice = await createDuplicateInvoice(requestPayload);
    if (getDuplicateInvoice?.status === API_CODE.STATUS_200) {
      navigate(PATH_PAGE.editInvoice + `?id=${getDuplicateInvoice?.data?.data?._id}`);
      setOnButtonloading(false);
    }
  };

  const getStatusFromView = (invoiceData) => {
    setStatusOfInvoice(invoiceData?.status);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleShare = (e) => {
    e.preventDefault();
    const title = 'View Your Invoice';
    const ahref = `${process.env.SITE_URL}/public?id=${id}`;
    const text = `${title} ${ahref}`;
    const encodedText = encodeURIComponent(text);
    const encodedAhref = encodeURIComponent(ahref);
    const encodedImage = encodeURIComponent(PreviewImage);
    let link;
    switch (e.currentTarget.id) {
      case 'facebook':
        link = `${SOCIAL_SHARE_LINK.FACEBOOK_URL}${ahref}`;
        open(link);
        break;
      case 'telegram':
        link = `${SOCIAL_SHARE_LINK.TELEGRAM_URL}${encodedAhref}&text=${title}`;
        open(link);
        break;
      case 'whatsapp':
        link = `${SOCIAL_SHARE_LINK.WHATSAPP_URL}${encodedText}&source&data&abid=12345&app_absent=true&thumb=${encodedImage}`;
        open(link);
        break;
      default:
        break;
    }
  };
  const open = (socialLink) => {
    typeof window !== 'undefined' && window.open(socialLink, '_blank');
  };
  const handleOpenShareModal = async (id) => {
    setSendInvoice(true);
    setSendEmailLoading(true);
    const checkSendEmailResponse = await checkSendEmail(id ? id : invoiceDetailsByID._id);
    if (checkSendEmailResponse?.data?.statusCode === API_CODE?.STATUS_200) {
      setShowAlreadySharedModal(false);
    } else {
      setShowAlreadySharedModal(true);
    }
    setSendEmailLoading(false);
  };
  return (
    <Layout>
      <Page title={PAGE_TITLE.VIEW_INVOICE}>
        <HeaderBreadcrumbs
          subtitleClass={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'mb-2' : 'mb-2'}
          titleContainerClass={{
            flexDirection: ['column', 'row', 'row'],
            alignItems: ['left', 'center', 'center'],
            width: '100%'
          }}
          sx={{ height: ['100%', '56px', '56px'], display: 'flex', alignItems: 'center' }}
          heading={PAGE_TITLE.VIEW_INVOICE}
          links={
            width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
              ? [
                  { name: PAGE_TITLE.DASHBOARD, href: PATH_PAGE.dashboard },
                  { name: PAGE_TITLE.GET_PAID, href: PATH_PAGE.invoiceList },
                  { name: `${invoiceDetailsByID?.invoiceNumber}` }
                ]
              : [{ name: '' }]
          }
          action={
            <div className="flex md:flex-row flex-col gap-2">
              <div className="flex md:flex-row gap-2">
                <DownloadInvoiceAsPDF getInvoiceDetails={invoiceDetailsByID} />
                <PrimaryButton
                  id="view-edit-btn"
                  bgColor="bg-white"
                  urlLink={PATH_PAGE.editInvoice + `?id=${id}`}
                  caption={
                    <div className="flex gap-1 items-center">
                      <IconEdit fill={MUI_COLORS.CORAL} />
                      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
                        <MSText textColor="text-coral-500" fontWeight="text-bold" title="Edit" />
                      )}
                    </div>
                  }
                  type="small"
                />
                <PrimaryButton
                  id="view-duplicate-btn"
                  bgColor="bg-white"
                  isLoading={onbuttonLoading}
                  caption={
                    <div className="flex gap-1 items-center">
                      <div className="w-5">
                        <Iconify sx={{ color: MUI_COLORS.CORAL }} icon={'heroicons-solid:document-duplicate'} />
                      </div>
                      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
                        <MSText textColor="text-coral-500" fontWeight="text-bold" title="Duplicate" />
                      )}
                    </div>
                  }
                  fontWeight="text-bold"
                  type="small"
                  onClick={() => duplicateInvoiceHandler()}
                />
                {statusOfInvoice !== 'Draft' && (
                  <div>
                    <PrimaryButton
                      id="view-share-btn"
                      bgColor="bg-white"
                      caption={
                        <div className="flex gap-1 items-center">
                          <Iconify sx={{ color: MUI_COLORS.CORAL }} icon={'mdi:share'} />
                          {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
                            <MSText textColor="text-coral-500" fontWeight="text-bold" title="Share" />
                          )}
                        </div>
                      }
                      type="small"
                      onClick={() => handleOpenShareModal()}
                    />
                  </div>
                )}
              </div>
              {!markedAsPaid &&
              (statusOfInvoice == INVOICE_STATUS.INVOICE_UNPAID ||
                statusOfInvoice == INVOICE_STATUS.INVOICE_PARTIAL_PAID ||
                statusOfInvoice == INVOICE_STATUS.INVOICE_REVIEW) ? (
                <PrimaryButton
                  id="view-mark-as-paid-btn"
                  isLoading={markAsPaidLoading}
                  caption={
                    <div className="flex gap-2 items-center">
                      <div className="w-6">
                        <CheckMark />
                      </div>
                      <MSText textColor="text-white" fontWeight="text-bold" title="Mark as Paid" />
                    </div>
                  }
                  fontWeight="text-bold"
                  type="small"
                  onClick={() => onMarkAsPaid()}
                />
              ) : (
                ''
              )}
            </div>
          }
        />
        <div className="flex md:flex-row flex-col md:gap-10">
          <PublicViewInvoice
            isInternalUserView={true}
            getInvoiceDetails={invoiceDetailsByID}
            isGetInvoiceDetailsLoading={isLoading}
          />
          <PaymentDetails
            getInvoiceDetails={invoiceDetailsByID}
            handleInvoiceDetailsbyID={handleInvoiceDetailsbyID}
            updateStatusOnPayment={updateStatusOnPayment}
            isGetInvoiceDetailsLoading={isLoading}
          />
        </div>
        <AddPaymentModal
          updateStatusOnPayment={updateStatusOnPayment}
          handleInvoiceDetailsbyID={handleInvoiceDetailsbyID}
          getInvoiceDetails={invoiceDetailsByID}
          openDynamicModal={addPayment}
          closeDynamicModal={closeAddPaymentModal}
        />
        <DynamicModal openDynamicModal={sendInvoice} closeDynamicModal={closeDynamicModal} minWidth="520px">
          <div className="flex flex-col items-center mt-8">
            <MDSubtitleText
              title={`Share your invoice with your \ncustomer to get paid`}
              fontWeight="text-bold"
              className="whitespace-pre-line line-height-26"
              textAlign="text-center"
            />
            {sendEmailLoading ? (
              <StatrysLoader />
            ) : (
              <div>
                {showAlreadySharedModal ? (
                  <div className="bg-green-200 rounded-lg p-4 my-8 flex gap-4 items-start">
                    <img src={TimeClockIcon} alt="time-clock" />
                    <div>
                      <XSText fontWeight="text-bold" title="You have already shared this invoice by email today." />
                      <XSText className="mt-2" title="Wait until tomorrow to send a reminder." />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <PrimaryButton
                      id="view-sent-by-email-btn"
                      className="invoice-primary-button mt-8"
                      caption="Send by email"
                      fontWeight="text-bold"
                      onClick={() => sendInvoiceByMail()}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="flex mt-4 mb-2 text-center">
              <div className="cursor-pointer" button id="facebook" onClick={handleShare}>
                <FacebookIcon />
                <BaseText className="social-share-title" fontSize="" title="by Facebook" />
              </div>
              <div className="ml-8 cursor-pointer" button id="whatsapp" onClick={handleShare}>
                <WhatsAppIcon />
                <BaseText className="social-share-title" fontSize="" title="by Whatsapp" />
              </div>
              <div className="ml-8 cursor-pointer" button id="telegram" onClick={handleShare}>
                <Telegram />
                <BaseText className="social-share-title" fontSize="" title="by Telegram" />
              </div>
            </div>
            <XSText
              title={
                <span>
                  <span>or copy a shareable </span>
                  <Link target="_blank" href={invoiceLink}>
                    link
                  </Link>
                </span>
              }
              className="leading-6 mt-8"
            />
            <div className="flex flex-row gap-2 mt-2">
              <div className="border-gray-200 rounded-lg border">
                <MSText
                  title={invoiceLink}
                  fontWeight="text-medium"
                  className="leading-6 p-4"
                  textColor="text-coral-500"
                />
              </div>
              <div className="cursor-pointer" onClick={copyInvoiceLink}>
                <CopyClipboard />
              </div>
            </div>
          </div>
        </DynamicModal>

        <DynamicModal openDynamicModal={sendByMail} closeDynamicModal={closeSendByMailDynamicModal} minWidth="520px">
          <div>
            <MDSubtitleText title={`Send Invoice`} fontWeight="text-bold" />
            {invoiceDetailsByID && (
              <form id="send-invoice-form" onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                  defaultValue={invoiceDetailsByID?.contact?.email}
                  name="email"
                  label="To"
                  variant="filled"
                  textFieldContainerClass="mt-4"
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: REGEX.TAGS_NOT_ALLOWED
                    }
                  })}
                  error={errors?.email ? true : false}
                  helperText={
                    errors?.email && <ValidationMessage title={defaultValidationMessage('email (Tags not allowed)')} />
                  }
                />
                <TextInput
                  defaultValue={`Invoice ${invoiceDetailsByID?.invoiceNumber} from ${invoiceDetailsByID?.companyDetails?.companyName}`}
                  name="subject"
                  label="Subject"
                  variant="filled"
                  textFieldContainerClass="mt-4"
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: REGEX.TAGS_NOT_ALLOWED
                    }
                  })}
                  error={errors?.subject ? true : false}
                  helperText={
                    errors?.subject && (
                      <ValidationMessage title={defaultValidationMessage('subject (Tags not allowed)')} />
                    )
                  }
                />
                <MultiTextInput
                  inputContainerClass="mt-4"
                  defaultValue={`Hello ${
                    invoiceDetailsByID?.contact?.firstName &&
                    capitalizeFirstLetter(invoiceDetailsByID?.contact?.firstName)
                  } ${
                    invoiceDetailsByID?.contact?.firstName &&
                    capitalizeFirstLetter(invoiceDetailsByID?.contact?.lastName)
                  },\nTo view the invoice for ${
                    (invoiceDetailsByID?.currency?.symbol
                      ? invoiceDetailsByID?.currency?.symbol
                      : invoiceDetailsByID?.currency?.name) + invoiceDetailsByID?.totalAmount
                  } please click the button below.\n\nSincerely,\n${
                    userProfileData?.firstName
                      ? `${capitalizeFirstLetter(userProfileData?.firstName)} ${
                          userProfileData?.lastName && capitalizeFirstLetter(userProfileData?.lastName)
                        } from ${invoiceDetailsByID?.companyDetails?.companyName}`
                      : `${invoiceDetailsByID?.companyDetails?.companyName}`
                  } `}
                  name="message"
                  rows="5"
                  label="Message for your customer"
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: REGEX.TAGS_NOT_ALLOWED
                    }
                  })}
                  error={errors?.message ? true : false}
                  helperText={
                    errors?.message && (
                      <ValidationMessage title={defaultValidationMessage('message (Tags not allowed')} />
                    )
                  }
                />

                <MSText
                  title={
                    <span>
                      <span>Your customer will receive this </span>
                      <Link target="_blank" href={invoiceLink}>
                        link
                      </Link>
                    </span>
                  }
                  className="leading-6 mt-2"
                  textColor="text-gray-450"
                />
                <div className="flex flex-row gap-4 mt-8">
                  <PrimaryButton
                    id="view-send-btn"
                    caption="Send"
                    type="small"
                    className="modal-save-button"
                    isLoading={isSendInvoiceLoading}
                    onClick={handleSubmit(onSubmit)}
                  />
                  <PrimaryButton
                    id="view-cancel-btn"
                    className="modal-cancel-button font-weight-bold border border-gray-400"
                    bgColor="bg-white"
                    color="text-gray-300"
                    caption="Cancel"
                    onClick={() => setSendByMail(false)}
                  />
                </div>
              </form>
            )}
          </div>
        </DynamicModal>
        <Snackbar
          open={isSnackBarOpen}
          handleClose={closeSnackbar}
          sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
          title={
            <div className="flex">
              <div className="flex items-center">
                <Image className="w-5 h-5" src={SuccessIcon} />
              </div>
              <div className="ml-4">
                <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage?.title}></XSText>
                <MSText textColor="text-gray-500" title={successMessage?.content}></MSText>
              </div>
            </div>
          }
        />
      </Page>
    </Layout>
  );
};

export const Head = () => (
  <Seo title="View Your Invoice" description="Create New Account to start creating new invoice." />
);
const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(invoiceActions.invoiceData(invoiceData)),
    editInvoice: (invoicedata) => dispatch(invoiceActions.editInvoice(invoicedata)),
    getInvoiceDetails: (invoiceId) => dispatch(invoiceActions.getInvoiceDetails(invoiceId)),
    sendEmailInvoice: (invoiceData) => dispatch(invoiceActions.sendEmailInvoice(invoiceData)),
    createDuplicateInvoice: (deleteInvoiceData) => dispatch(invoiceActions.createInvoice(deleteInvoiceData)),
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    editContact: (editedDetails) => dispatch(contactActions.editContact(editedDetails)),
    invoiceCount: (invoiceId) => dispatch(invoiceActions.invoiceCount(invoiceId)),
    checkSendEmail: (invoiceId) => dispatch(commonActions.checkSendEmail(invoiceId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);

View.propTypes = {
  invoiceData: PropTypes.func,
  getInvoiceDetails: PropTypes.object,
  sendEmailInvoice: PropTypes.func,
  editInvoice: PropTypes.func,
  getUserProfile: PropTypes.func,
  createDuplicateInvoice: PropTypes.func,
  editContact: PropTypes.func,
  invoiceCount: PropTypes.func
};
