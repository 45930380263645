import React from 'react';
import Layout from '../../components/layout';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PAGE_TITLE, RESPONSIVENESS_BREAKPOINT_VALUE } from '../../constants/enum';
import Page from '../../components/Page';
import Seo from '../../components/seo';
import ImportedInvoice from '../../components/importedInvoices/ImportedInvoice';
import { PATH_PAGE } from '../../routes/paths';
import CheckMark from '../../images/icons/customSvg/CheckMark';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import MSText from '../../components/ui/typography/MSText';
import DownloadIcon from '../../images/icons/download-icon.svg';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function CreateImportingInvoice() {
  const { width } = useWindowDimensions();
  const isLoading = useSelector((state) => state.importingInvoiceReducer?.loading);

  return (
    <Layout>
      <Page title="Importing Invoice">
        <HeaderBreadcrumbs
          subtitleClass={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'mb-2' : 'mb-2'}
          titleContainerClass={{
            flexDirection: ['column', 'row', 'row'],
            alignItems: ['left', 'center', 'center'],
            width: '100%'
          }}
          heading={PAGE_TITLE.IMPORTING_INVOICE}
          links={
            width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
              ? [{ name: PAGE_TITLE.GET_PAID, href: PATH_PAGE.invoiceList }, { name: PAGE_TITLE.IMPORTING_INVOICE }]
              : [{ name: '' }]
          }
          action={
            <div>
              <button positive form="imported-invoice-form" type="submit">
                <PrimaryButton
                  id="create-invoice-finalize-btn"
                  isLoading={isLoading}
                  caption={
                    <div className="flex gap-2 items-center">
                      <div className="w-6">
                        <CheckMark />
                      </div>
                      <MSText textColor="text-white" fontWeight="text-bold" title="Finalize" />
                    </div>
                  }
                  fontWeight="text-bold"
                  type="small"
                />
              </button>
            </div>
          }
        />
        <ImportedInvoice />
      </Page>
    </Layout>
  );
}

export const Head = () => (
  <Seo title="View Your Invoice" description="Create Your invoice by Register on our website" />
);
