import React, { useState } from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import MDSubtitleText from './typography/MDSubtitleText';
import XXSText from './typography/XXSText';
import PropTypes from 'prop-types';
import PrimaryButton from './buttons/PrimaryButton';
import SelectInputAutoComplete from './inputs/selectInputAutoComplete';
import TextInput from './inputs/TextInput';
import { InputAdornment } from '@mui/material';
import ValidationMessage from './ValidationMessage';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import UploadPaymentProof from '../publicInvoice/UploadPaymentProof';
import * as invoicePaymentReduxActions from '../invoicePaymentDetails/reduxStore/action';
import { API_CODE, MUI_COLORS, PAYMENT_STATUS_INVOICE } from '../../constants/enum';
import { connect } from 'react-redux';
import * as commonReduxActions from '../commonReduxStore/reduxStore/action';
import * as invoiceReduxActions from '../invoice/reduxStore/action';
import BaseText from './typography/BaseText';

const options = [
  {
    name: 'Cash'
  },
  {
    name: 'Cheque'
  },
  {
    name: 'Credit Card'
  },
  {
    name: 'Bank Transfer'
  },
  {
    name: 'PayPal'
  },
  {
    name: 'PayMe'
  }
];

function InvoiceStatusSelectAlertModal({
  title,
  message,
  openDynamicModal,
  closeDynamicModal,
  getInvoiceDetails,
  paymentDetails,
  editInvoice,
  invoiceId,
  invoiceUpdateStatus,
  selectedMenu,
  invoiceMarkPayment,
  uploadImage,
  handleInvoiceDetailsbyID
}) {
  const { register, handleSubmit, errors, watch } = useForm();
  const [paymentType, setPaymentType] = useState('');
  const [saveImage, setSaveImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');

  const fileSelection = (acceptedFiles) => {
    setAcceptedFile(acceptedFiles);
    let selectedimg = acceptedFiles?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setSelectedImage(selectedimg);
    setSaveImage(true);
  };

  const onSubmit = (data) => {
    if (saveImage) {
      uploadPaymentProof(data);
    } else {
      invoiceMarkPaymentDetails(data);
    }
  };

  const uploadPaymentProof = async (data) => {
    const formData = new FormData();
    formData.append('file', acceptedFile[0]);
    const uploadedProfileResponse = await uploadImage(formData);
    if (uploadedProfileResponse?.status === API_CODE.STATUS_200) {
      invoiceMarkPaymentDetails(data, uploadedProfileResponse?.data?.data?.key);
    }
  };

  const invoiceMarkPaymentDetails = async (data, paymentLink) => {
    const payload = {
      paidDate: data?.paidDate,
      paymentType: paymentType?.name,
      paidAmount: data?.amount ? Number(data?.amount) : getInvoiceDetails?.totalAmount,
      paymentProof: paymentLink,
      invoiceId: invoiceId
    };
    const invoiceMarkPaymentResponse = await invoiceMarkPayment(payload);
    if (invoiceMarkPaymentResponse?.status === API_CODE.STATUS_200) {
      const remaining = invoiceMarkPaymentResponse?.data?.data?.payments?.reduce((accumulator, object) => {
        return accumulator + object.paidAmount;
      }, 0);
      if (remaining) {
        editInvoiceHandler(invoiceMarkPaymentResponse?.data?.data, remaining);
      }
    }
  };

  const remaining = getInvoiceDetails?.payments?.reduce((accumulator, object) => {
    return accumulator + object.paidAmount;
  }, 0);

  const editInvoiceHandler = async (invoiceData, remainingAmount) => {
    const payload = {
      invoiceId: invoiceId,
      pendingAmount: remainingAmount
        ? invoiceData?.totalAmount - remainingAmount
        : invoiceData?.totalAmount - (remaining + Number(remainingAmount))
    };
    const editInvoiceResponse = await editInvoice(payload);
    if (editInvoiceResponse?.status === API_CODE.STATUS_200) {
      handleUpdateSatusChange();
    }
  };

  const handleUpdateSatusChange = async () => {
    const payload = {
      invoiceId: invoiceId,
      status: selectedMenu?.name
    };
    const editInvoiceStatusResponse = await invoiceUpdateStatus(payload);
    if (editInvoiceStatusResponse?.status === API_CODE.STATUS_200) {
      handleInvoiceDetailsbyID();
      closeDynamicModal();
    }
  };
  return (
    <>
      <DynamicModal openDynamicModal={openDynamicModal} closeDynamicModal={closeDynamicModal} minWidth="520px">
        <div className="mt-10">
          <MDSubtitleText className="flex mb-4 status-alert-title" title={title} fontWeight="text-bold" />
        </div>
        <div className="mb-4">
          <BaseText
            fontSize="text-xl"
            className="status-alert-message"
            textColor="text-gray-500"
            title={getInvoiceDetails?.paymentStatus !== PAYMENT_STATUS_INVOICE.PAID ? message : ''}
          />
        </div>
        {paymentDetails && getInvoiceDetails?.paymentStatus !== PAYMENT_STATUS_INVOICE.PAID ? (
          <form id="add-invoice-payment" className="mx-10" onSubmit={handleSubmit(onSubmit)}>
            <SelectInputAutoComplete
              name="paymentType"
              variant="filled"
              defaultValue={paymentType}
              value={paymentType}
              setSelectedValue={setPaymentType}
              disableClearable={true}
              inputRef={register({
                required: false
              })}
              label="Payment type"
              options={options}
            />
            <TextInput
              defaultValue={moment(new Date()).format('YYYY-MM-DD')}
              name="paidDate"
              label="Date"
              type="date"
              variant="filled"
              textFieldContainerClass="mt-4"
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register({
                required: true
              })}
              error={errors?.paidDate ? true : false}
              helperText={errors?.paidDate && <ValidationMessage title={'Please provide the date'} />}
            />
            <TextInput
              defaultValue={
                getInvoiceDetails?.payments?.length > 0
                  ? getInvoiceDetails?.pendingAmount
                  : getInvoiceDetails?.totalAmount
              }
              startAdornment={
                <InputAdornment position="start">
                  {getInvoiceDetails?.invoiceCurrency?.symbol
                    ? getInvoiceDetails?.invoiceCurrency?.symbol
                    : getInvoiceDetails?.invoiceCurrency?.name}
                </InputAdornment>
              }
              name="amount"
              label="Amount"
              variant="filled"
              disabled={true}
              backgroundColor={MUI_COLORS.GRAY_FOR_DISABLE_INPUTS}
              textFieldContainerClass="mt-4"
              inputRef={register({
                required: true
              })}
              error={errors?.amount ? true : false}
              helperText={errors?.amount && <ValidationMessage title={'Please provide the amount'} />}
            />
            <UploadPaymentProof
              saveImage={saveImage}
              selectedImage={selectedImage}
              uploadError={uploadError}
              acceptedFile={acceptedFile?.[0]}
              fileSelection={fileSelection}
            />
            <div className="flex justify-center flex-row gap-4 mt-8 mb-4">
              <PrimaryButton
                id="invoice-status-select-alert-save-btn"
                caption="Save Payment"
                type="small"
                className="modal-save-button"
                onClick={handleSubmit(onSubmit)}
              />
              <PrimaryButton
                id="invoice-status-select-payment-cancel-btn"
                className="modal-cancel-button font-weight-bold border border-gray-400"
                bgColor="bg-white"
                color="text-gray-300"
                caption="Cancel"
                onClick={closeDynamicModal}
              />
            </div>
          </form>
        ) : (
          <div className="flex justify-center">
            <PrimaryButton
              id="invoice-status-select-payment-confirm-btn"
              fontWeight="text-bold"
              className="invoice-status-modal-button"
              caption="Confirm"
              onClick={handleUpdateSatusChange}
            />
            <PrimaryButton
              id="invoice-status-select-cancel-btn"
              fontWeight="text-bold"
              bgColor="text-white"
              className="invoice-status-modal-button ml-4"
              color="text-coral-500"
              caption="Cancel"
              onClick={closeDynamicModal}
            />
          </div>
        )}
      </DynamicModal>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceMarkPayment: (paymentData) => dispatch(invoicePaymentReduxActions.invoiceMarkPayment(paymentData)),
    editInvoice: (payload) => dispatch(invoiceReduxActions.editInvoice(payload)),
    invoiceUpdateStatus: (payload) => dispatch(invoicePaymentReduxActions.invoiceUpdateStatus(payload)),
    uploadImage: (uploadedImage) => dispatch(commonReduxActions.uploadDocument(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(InvoiceStatusSelectAlertModal);

InvoiceStatusSelectAlertModal.propTypes = {
  title: PropTypes.string,
  openDynamicModal: PropTypes.bool,
  closeDynamicModal: PropTypes.func,
  handleUpdateSatusChange: PropTypes.func,
  editInvoice: PropTypes.func,
  invoiceMarkPayment: PropTypes.func
};
