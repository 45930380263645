import React from 'react';

function ArrowDown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M15.5 4.87305L8.35333 12.019C8.30696 12.0655 8.25188 12.1023 8.19125 12.1275C8.13062 12.1526 8.06563 12.1655 8 12.1655C7.93437 12.1655 7.86938 12.1526 7.80875 12.1275C7.74812 12.1023 7.69304 12.0655 7.64667 12.019L0.5 4.87305"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ArrowDown;
