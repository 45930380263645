import React, { useState } from 'react';
import TextInput from '../ui/inputs/TextInput';
import ValidationMessage from '../ui/ValidationMessage';
import IconInvoiceTrash from '../../images/icons/Invoice/invoice-trash.svg';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import Plus from '../../images/icons/customSvg/plus';
import { invoiceInputBackgroundColor, defaultValidationMessage, multiplyNumbers } from '../../helper/helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from './reduxStore/action';
import XXSText from '../ui/typography/XXSText';
import { units } from '../../data/units';
import UnitSelectAutoComplete from '../ui/inputs/UnitSelectAutoComplete';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { RESPONSIVENESS_BREAKPOINT_VALUE } from '../../constants/enum';
import MSText from '../ui/typography/MSText';

function InvoiceItems({ invoiceData, register, errors, selectedCurrency, getInvoiceDetails, isFinalize }) {
  let itemsObject = {
    itemTitle: '',
    itemDescription: '',
    unit: units[0],
    itemQuantity: '',
    itemPriceRate: '',
    itemTotal: ''
  };
  const [formValues, setFormValues] = useState(getInvoiceDetails?.items ? getInvoiceDetails?.items : [itemsObject]);
  const { width } = useWindowDimensions();
  let unit = 'unit';
  let handleChange = (index, event, name) => {
    let newFormValues = [...formValues];
    newFormValues[index][name] = name === unit ? event : event.target.value;
    if (formValues[index]['itemQuantity'] && formValues[index]['itemPriceRate']) {
      newFormValues[index]['itemTotal'] = String(
        multiplyNumbers([formValues[index]['itemQuantity'], formValues[index]['itemPriceRate']])
      );
    } else {
      newFormValues[index]['itemTotal'] = '';
    }
    setFormValues(newFormValues);
    invoiceData({ items: newFormValues });
  };

  let addFormFields = () => {
    setFormValues([...formValues, itemsObject]);
  };

  let removeFormFields = (index) => {
    let newFormValues = [...formValues];
    newFormValues.splice(index, 1);
    setFormValues(newFormValues);
    invoiceData({ items: newFormValues });
  };

  const handleUnitValue = (index, value, name) => {
    let newFormValues = [...formValues];
    let unitNewOption = {
      name: value,
      priceOrRate: 'Price'
    };
    newFormValues[index][name] = unitNewOption;
  };

  return (
    <div>
      <XXSText className="md:my-6 my-4" textColor="text-gray-600" title="Description of items" />
      {formValues.map((element, index) => {
        return (
          <div className="flex flex-col gap-2">
            <div className="flex md:flex-row flex-col gap-2 md:mt-3 invoice-right-padding" key={index}>
              <TextInput
                textFieldContainerClass="invoice-items-and-task-title"
                name={`itemTitle${index}`}
                label="Item"
                variant="filled"
                defaultValue={element?.itemTitle || ''}
                onChange={(event) => handleChange(index, event, 'itemTitle')}
                backgroundColor={invoiceInputBackgroundColor(element?.itemTitle)}
                inputRef={register({
                  required: isFinalize
                })}
                error={errors?.[`itemTitle${index}`] ? true : false}
                helperText={
                  errors?.[`itemTitle${index}`] && (
                    <ValidationMessage title={defaultValidationMessage('Goods details')} />
                  )
                }
              />

              <div className="flex flex-col md:flex-row gap-2">
                <UnitSelectAutoComplete
                  label="Unit"
                  name={`unit${index}`}
                  options={units}
                  defaultValue={element?.unit?.name ? element?.unit?.name : units[0]}
                  setSelectedValue={(value) => handleChange(index, value, unit)}
                  value={formValues?.unit?.name}
                  setCustomOption={(value) => handleUnitValue(index, value, unit)}
                />
                <TextInput
                  textFieldContainerClass="invoice-items-quantity-and-task-hour"
                  name={`itemQuantity${index}`}
                  label="Qty"
                  variant="filled"
                  type="number"
                  value={element?.itemQuantity || ''}
                  onChange={(e) => handleChange(index, e, 'itemQuantity')}
                  backgroundColor={invoiceInputBackgroundColor(element?.itemQuantity)}
                  inputRef={register({
                    required: isFinalize
                  })}
                  error={errors?.[`itemQuantity${index}`] ? true : false}
                />
                <TextInput
                  textFieldContainerClass="invoice-items-price-and-task-rate"
                  name={`itemPriceRate${index}`}
                  label={element?.unit?.priceOrRate ? element?.unit?.priceOrRate : units?.[0]?.priceOrRate}
                  variant="filled"
                  type="number"
                  value={element?.itemPriceRate || ''}
                  startAdornment={
                    element?.itemPriceRate
                      ? selectedCurrency?.symbol
                        ? selectedCurrency?.symbol
                        : selectedCurrency?.name
                      : null
                  }
                  onChange={(e) => handleChange(index, e, 'itemPriceRate')}
                  backgroundColor={invoiceInputBackgroundColor(element?.itemPriceRate)}
                  inputRef={register({
                    required: isFinalize
                  })}
                  error={errors?.[`itemPriceRate${index}`] ? true : false}
                />

                <div className="flex self-start w-full md:w-auto items-center">
                  <TextInput
                    textFieldContainerClass="invoice-items-and-task-total"
                    name={`itemTotal${index}`}
                    label="Total"
                    variant="filled"
                    value={
                      element?.itemQuantity &&
                      element?.itemPriceRate &&
                      multiplyNumbers([element?.itemQuantity, element?.itemPriceRate])
                    }
                    startAdornment={
                      multiplyNumbers([element?.itemQuantity, element?.itemPriceRate])
                        ? selectedCurrency?.symbol
                          ? selectedCurrency?.symbol
                          : selectedCurrency?.name
                        : null
                    }
                    backgroundColor={invoiceInputBackgroundColor(
                      multiplyNumbers([element?.itemQuantity, element?.itemPriceRate])
                    )}
                    inputRef={register({
                      required: false
                    })}
                  />

                  {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
                    <img
                      onClick={() => removeFormFields(index)}
                      src={IconInvoiceTrash}
                      alt="IconInvoiceTrash"
                      className="cursor-pointer -mr-10 pl-2 md:pl-5"
                    />
                  )}
                </div>
                {width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
                  <TextInput
                    textFieldContainerClass="invoice-items-and-task-description"
                    name={`itemDescription${index}`}
                    label="Description"
                    variant="filled"
                    value={element?.itemDescription || ''}
                    onChange={(e) => handleChange(index, e, 'itemDescription')}
                    backgroundColor={invoiceInputBackgroundColor(element?.itemDescription)}
                    inputRef={register({
                      required: false
                    })}
                  />
                )}
              </div>
              {width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
                <PrimaryButton
                  id="invoice-items-add-btn"
                  className="invoice-button md:mt-4 mt-0 md:mb-0 mb-2 md:w-auto w-full border border-gray-500"
                  bgColor="bg-white"
                  isBorderRequired={true}
                  color="text-gray-500"
                  onClick={() => removeFormFields(index)}
                  caption={
                    <div className="flex gap-2 items-center justify-center">
                      <img src={IconInvoiceTrash} alt="IconInvoiceTrash" className="cursor-pointer" />
                      <MSText fontWeight="text-bold" className="flex items-end" title="Delete" />
                    </div>
                  }
                />
              )}
            </div>
            {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
              <TextInput
                textFieldContainerClass="invoice-items-and-task-description"
                name={`itemDescription${index}`}
                label="Description"
                variant="filled"
                value={element?.itemDescription || ''}
                onChange={(e) => handleChange(index, e, 'itemDescription')}
                backgroundColor={invoiceInputBackgroundColor(element?.itemDescription)}
                inputRef={register({
                  required: false
                })}
              />
            )}
          </div>
        );
      })}
      <div className="md:flex md:w-auto w-full md:pr-0 pr-4">
        <PrimaryButton
          id="invoice-items-add-btn"
          className="invoice-button md:mt-4 mt-2 md:w-auto w-full"
          bgColor="bg-coral-500"
          textColor="text-white"
          onClick={() => addFormFields()}
          caption={
            <div className="flex gap-2 text-medium items-center justify-center">
              <Plus />
              Add item
            </div>
          }
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceItems);

InvoiceItems.propTypes = {
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func
};
