import React from 'react';

function ArrowUp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M0.5 11.127L7.64667 3.98095C7.69304 3.93451 7.74812 3.89766 7.80875 3.87253C7.86938 3.84739 7.93437 3.83445 8 3.83445C8.06563 3.83445 8.13062 3.84739 8.19125 3.87253C8.25188 3.89766 8.30696 3.93451 8.35333 3.98095L15.5 11.127"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ArrowUp;
