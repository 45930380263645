import React from 'react';

function SwitchToStatrys() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M1.90625 4.71875H14.0938C14.0938 4.71875 15.0312 4.71875 15.0312 5.65625V13.1562C15.0312 13.1562 15.0312 14.0938 14.0938 14.0938H1.90625C1.90625 14.0938 0.96875 14.0938 0.96875 13.1562V5.65625C0.96875 5.65625 0.96875 4.71875 1.90625 4.71875Z"
        stroke="#E15741"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8125 4.71875C10.8125 3.97283 10.5162 3.25746 9.98874 2.73001C9.46129 2.20257 8.74592 1.90625 8 1.90625C7.25408 1.90625 6.53871 2.20257 6.01126 2.73001C5.48382 3.25746 5.1875 3.97283 5.1875 4.71875"
        stroke="#E15741"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M0.96875 8.46875H6.59375" stroke="#E15741" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.40625 8.46875H15.0312" stroke="#E15741" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.59375 9.09375C6.59375 9.46671 6.74191 9.8244 7.00563 10.0881C7.26935 10.3518 7.62704 10.5 8 10.5C8.37296 10.5 8.73065 10.3518 8.99437 10.0881C9.25809 9.8244 9.40625 9.46671 9.40625 9.09375V8.34375C9.40625 7.97079 9.25809 7.6131 8.99437 7.34938C8.73065 7.08566 8.37296 6.9375 8 6.9375C7.62704 6.9375 7.26935 7.08566 7.00563 7.34938C6.74191 7.6131 6.59375 7.97079 6.59375 8.34375V9.09375Z"
        stroke="#E15741"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SwitchToStatrys;
