import { Link } from 'gatsby';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import XMSText from '../ui/typography/XMSText';
import MSText from '../ui/typography/MSText';
import Avatar from '../ui/Avatar';
import { stringTruncate } from '../../helper/helpers';
import { STRING_MAX_CHAR } from '../../constants/enum';
import { Divider } from '@mui/material';
import '../../styles/headerNavigation.scss';

export default function NavigationDropDown({
  menuItems,
  needSeparator,
  separtorPositions,
  children,
  companyList,
  isNavigation
}) {
  const [onHover, setOnHover] = useState();

  return (
    <>
      <div className={`${isNavigation ? 'dropdown-navigation-container' : 'dropdown-container'} -ml-10`}>
        <div className="dropdown-content">
          <div className="tooltip-container">
            <div className="tooltip-icon"></div>
          </div>
          <div className="dropdown-content-container flex flex-col " onMouseLeave={() => setOnHover()}>
            {children}
            {companyList && (
              <>
                <Divider sx={{ marginTop: '16px' }} />
                <div className="p-2">
                  {companyList?.map((company) => {
                    return (
                      <div className="flex flex-row gap-2 items-center cursor-pointer p-2">
                        <div className="rounded-full">
                          <Avatar
                            avatarStyling="w-10 h-10"
                            avatarImage={company?.logoPublic}
                            avatarName={company?.companyName && company?.companyName?.charAt(0)}
                          />
                        </div>
                        <MSText
                          title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                          fontWeight="text-bold"
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            <div className="mb-2">
              {menuItems.map((menu, index) => {
                return (
                  <>
                    {needSeparator && separtorPositions.includes(index) ? (
                      <div className="navigation-dropdown-bottom-line my-2"></div>
                    ) : null}
                    {menu.onMenuItemClick ? (
                      <div onClick={menu.onMenuItemClick} className="hover:no-underline cursor-pointer ">
                        <div
                          onMouseEnter={() => setOnHover(index)}
                          className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${
                            index === onHover ? 'bg-gray-100 ' : ''
                          }`}
                          key={index}
                        >
                          <div>{menu?.icon}</div>
                          <MSText
                            title={menu?.pageName}
                            textColor="text-blue-300"
                            className="dropdown-email-text ml-2"
                          />

                          {menu?.isNewMenuItem && (
                            <div className="bg-coral-500 rounded-lg ml-2">
                              <XMSText title="New" textColor="text-white" className="px-2 py-1" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <Link to={menu?.to} className="hover:no-underline cursor-pointer ">
                        <div
                          onMouseEnter={() => setOnHover(index)}
                          className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${
                            index === onHover ? 'bg-gray-100 ' : ''
                          }`}
                          key={index}
                        >
                          <div>{menu?.icon}</div>
                          <MSText
                            title={menu?.pageName}
                            textColor="text-blue-300"
                            className="dropdown-email-text ml-2"
                          />

                          {menu?.isNewMenuItem && (
                            <div className="bg-coral-500 rounded-lg ml-2">
                              <XMSText title="New" textColor="text-white" className="px-2 py-1" />
                            </div>
                          )}
                        </div>
                      </Link>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

NavigationDropDown.propTypes = {
  menuItems: PropTypes.array,
  needSeparator: PropTypes.bool,
  separtorPositions: PropTypes.array,
  children: PropTypes.any
};
