import React from 'react';
import ImportedInvoiceFileUpload from './ImportedInvoiceFileUpload';
import ImportedInvoiceForm from './ImportedInvoiceForm';
import '../../styles/pages/imported-invoice.scss';

export default function ImportedInvoice() {
  return (
    <>
      <div className="md:flex">
        <div className="md:w-2/5 md:mb-0 mb-4">
          <ImportedInvoiceFileUpload />
        </div>
        <div className="md:w-3/5">
          <ImportedInvoiceForm />
        </div>
      </div>
    </>
  );
}
