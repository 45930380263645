import React, { useEffect, useState } from 'react';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
import { navigate } from 'gatsby';
import {
  API_CODE,
  CONTACT_TABLE_TITLE,
  INVOICE_STATUS,
  RESPONSIVENESS_BREAKPOINT_VALUE,
  ROW_PER_PAGE_OPTIONS,
  STATUS_COLORS,
  TYPE_OF_INVOICE
} from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import sumBy from 'lodash/sumBy';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Tooltip,
  Divider,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination
} from '@mui/material';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { TableNoData, TableEmptyRows, TableHeadCustom, TableSelectedActions } from '../table';
import ViewContactTableRow from '../../sections/@contact/contact-view/ViewContactTableRow';
import { getTimeDate } from '../../utils/formatTime';
import Label from '../Label';
import ContactListAnalytic from '../../sections/@contact/ContactListAnalytic';
import ContactListSupplierAnalytic from '../../sections/@contact/ContactListSupplierAnalytics';
import { connect } from 'react-redux';
import { useLocation } from '@reach/router';
import * as contactActions from '../../components/contact/reduxStore/action';
import * as importingInvoiceActions from '../../components/importedInvoices/reduxStore/action';
import PropTypes from 'prop-types';
import ContactStartedBanner from './contactBanners/ContactStartedBanner';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import * as invoiceActions from '../invoice/reduxStore/action';
import * as profileActions from '../profile/reduxStore/action';
import ContactSupplierBanner from './contactBanners/ContactSupplierBanner';
import { addCommas, findTotalCharacter, convertDateToDDMMYYYY } from '../../helper/helpers';
import XSText from '../ui/typography/XSText';
import useWindowDimensions from '../../hooks/useWindowDimensions';
function ViewContactList({
  getInvoiceContactList,
  getContactDetails,
  createDuplicateInvoice,
  getInvoiceDetails,
  invoiceCount,
  invoiceCurrency,
  getCurrencyPreference,
  getInvoiceSupplierList,
  deleteImportedInvoice,
  getImportingDashboardData
}) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({ defaultOrderBy: 'companyName' });
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalAmountCurrency, setTotalAmountCurrency] = useState('');
  const [contactType, setContactType] = useState('');
  const [currency, setCurrency] = useState('');
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('id');
  const { width } = useWindowDimensions();

  useEffect(() => {
    handleInvoiceList();
    if (location?.state?.contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS) {
      totalInvoiceAmount();
    }
    if (location?.state?.contactType === CONTACT_TABLE_TITLE.ONLY_SUPPLIERS) {
      totalImportingInvoiceAmount();
    }
    getPreferedCurrency();
  }, []);
  const handleInvoiceList = async () => {
    if (location?.state?.contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS) {
      getInvoiceListByContactId(id);
    } else {
      getInvoiceListBySupplierId(id);
    }
    setContactType(location?.state?.contactType);
  };

  const getInvoiceListByContactId = async (id) => {
    const getInvoiceListResponse = await getInvoiceContactList(id);
    if (getInvoiceListResponse?.status === API_CODE.STATUS_200) {
      setTableData(getInvoiceListResponse?.data?.data?.invoiceData);
      setIsLoading(false);
    } else {
      setError(true);
      setIsLoading(false);
    }
  };

  const getInvoiceListBySupplierId = async (id) => {
    const requestedData = {
      supplierId: id
    };
    const getInvoiceListResponse = await getInvoiceSupplierList({ params: { ...requestedData } });
    if (getInvoiceListResponse?.status === API_CODE.STATUS_200) {
      setTableData(getInvoiceListResponse?.data?.data?.invoiceData);
      setIsLoading(false);
    } else {
      setError(true);
      setIsLoading(false);
    }
  };

  const getPreferedCurrency = async () => {
    const getCurrencyDataResponse = await getCurrencyPreference();
    if (getCurrencyDataResponse?.status === API_CODE.STATUS_200) {
      setCurrency(getCurrencyDataResponse?.data?.data?.currency);
    }
  };

  const invoiceCountHandler = async (invoiceData) => {
    const payload = {
      contactId: invoiceData?.contact?.contactId
    };
    const invoiceCountResponse = await invoiceCount(payload);
    if (invoiceCountResponse?.status === API_CODE.STATUS_200) {
      createDuplicateInvoiceHandler(invoiceData, invoiceCountResponse?.data?.data);
    }
  };

  const onDuplicateRowHandler = async (id) => {
    const getInvoiceResponse = await getInvoiceDetails(id);
    if (getInvoiceResponse?.status === API_CODE.STATUS_200) {
      invoiceCountHandler(getInvoiceResponse?.data?.data);
    }
  };

  const createDuplicateInvoiceHandler = async (invoiceResponse, invoiceCount) => {
    const requestPayload = {
      companyDetails: invoiceResponse?.companyDetails,
      contact: invoiceResponse?.contact,
      services: invoiceResponse?.services,
      goods: invoiceResponse?.goods,
      taxWithPrice: invoiceResponse?.taxWithPrice,
      invoiceDiscount: invoiceResponse?.invoiceDiscount,
      currency: invoiceResponse?.currency,

      notes: invoiceResponse?.notes,
      invoiceNumber: `INV-${invoiceCount}`,
      dueDate: invoiceResponse?.dueDate ? invoiceResponse?.dueDate : '',
      createDate: invoiceResponse?.createDate,
      otherReferences: invoiceResponse?.otherReferences,
      totalAmount: invoiceResponse?.totalAmount,
      subtotal: invoiceResponse?.subtotal,
      logoPublic: invoiceResponse?.logoPublic,
      paymentDetails: invoiceResponse?.paymentDetails,
      status: INVOICE_STATUS.INVOICE_DRAFT,
      userId: invoiceResponse?.userId
    };

    const getDuplicateInvoice = await createDuplicateInvoice(requestPayload);
    if (getDuplicateInvoice?.status === API_CODE.STATUS_200) {
      navigate(PATH_PAGE.editInvoice + `?id=${getDuplicateInvoice?.data?.data?._id}`);
    }
  };

  const onArchiveRow = (id) => {
    const deleteRow = tableData?.filter((row) => row?._id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const onDeleteRow = (id) => {
    const deleteRow = tableData?.filter((row) => row?._id !== id);
    deleteImportedInvoiceHandler(id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData?.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id) => {
    if (contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS) {
      navigate(PATH_PAGE.editInvoice + `?id=${id}`);
    }
    if (contactType === CONTACT_TABLE_TITLE.ONLY_SUPPLIERS) {
      navigate(PATH_PAGE.editImportingInvoice + `?id=${id}`);
    }
  };

  const handleViewRow = (id) => {
    if (contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS) {
      navigate(PATH_PAGE.viewInvoice + `?id=${id}`);
    }
    if (contactType === CONTACT_TABLE_TITLE.ONLY_SUPPLIERS) {
      navigate(PATH_PAGE.viewIncomingInvoices + `?id=${id}`);
    }
  };

  const totalInvoiceAmount = async () => {
    const payload = {
      contactId: id,
      dashboardType: TYPE_OF_INVOICE.INVOICE
    };
    const invoiceTotalAmountResponse = await invoiceCurrency({ params: { ...payload } });
    if (invoiceTotalAmountResponse?.status === API_CODE.STATUS_200) {
      setTotalAmountCurrency(invoiceTotalAmountResponse?.data?.data);
    }
  };

  const totalImportingInvoiceAmount = async () => {
    const request = {
      supplierId: id,
      dashboardType: TYPE_OF_INVOICE.IMPORTING_INVOICE
    };
    const invoiceDashboardDataResponse = await getImportingDashboardData({ params: { ...request } });
    if (invoiceDashboardDataResponse?.status === API_CODE.STATUS_200) {
      setTotalAmountCurrency(invoiceDashboardDataResponse?.data?.data);
    }
  };

  const deleteImportedInvoiceHandler = async (invoiceId) => {
    const deletedInvoiceResponse = await deleteImportedInvoice(invoiceId);
    if (deletedInvoiceResponse?.status === API_CODE.STATUS_200) {
      getInvoiceListBySupplierId(id);
    }
  };

  const TABLE_HEAD = [
    {
      id: 'invoiceNumber',
      label: 'Invoice Number',
      align: 'left'
    },
    { id: 'totalAmount', label: 'Amount', align: 'left' },
    { id: 'status', label: 'Status', align: 'center' },
    { id: '' }
  ];

  const getLengthByStatus = (status) => tableData?.filter((item) => item.status === status).length;

  const getPercentByStatus = (status) => (getLengthByStatus(status) / tableData?.length) * 100;

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs(CONTACT_TABLE_TITLE.ALL);
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterStatus
  });

  const isNotFound = (!dataFiltered?.length && !!tableData) || (!dataFiltered?.length && !!filterStatus);

  const TABS = [
    {
      value: INVOICE_STATUS.INVOICE_TOTAL,
      label: INVOICE_STATUS.INVOICE_TOTAL,
      color: 'error',
      count: tableData?.length
    },
    {
      value: INVOICE_STATUS.INVOICE_PAID,
      label: INVOICE_STATUS.INVOICE_PAID,
      color: 'success',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_PAID),
      variant: 'filled'
    },
    {
      value: INVOICE_STATUS.INVOICE_UNPAID,
      label: INVOICE_STATUS.INVOICE_UNPAID,
      color: 'warning',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_UNPAID),
      variant: 'filled'
    },
    {
      value: INVOICE_STATUS.INVOICE_OVERDUE,
      label: INVOICE_STATUS.INVOICE_OVERDUE,
      color: 'error',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_OVERDUE),
      variant: 'filled'
    },
    {
      value:
        contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS ? INVOICE_STATUS.INVOICE_DRAFT : INVOICE_STATUS.INVOICE_REVIEW,
      label:
        contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS ? INVOICE_STATUS.INVOICE_DRAFT : INVOICE_STATUS.INVOICE_REVIEW,
      color: 'default',
      count: getLengthByStatus(
        contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS ? INVOICE_STATUS.INVOICE_DRAFT : INVOICE_STATUS.INVOICE_REVIEW
      ),
      variant: 'filled'
    }
  ];

  return (
    <>
      {isLoading && <StatrysLoader />}
      {tableData?.length > 0 && (
        <>
          {contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS ? (
            <div className="flex lg:flex-row flex-col gap-2 mb-6">
              <ContactListSupplierAnalytic
                title="Total paid"
                analyticsClass="w-full lg:w-4/12"
                total={getLengthByStatus(INVOICE_STATUS.INVOICE_PAID)}
                percent={getPercentByStatus(INVOICE_STATUS.INVOICE_PAID)}
                currency={currency}
                isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
                price={addCommas(totalAmountCurrency?.Paid?.toFixed(2))}
                rootClass="bg-green-500 mr-2 "
                titleClass="text-green-200"
                amountColor="text-white"
                totalClass="text-white"
                invoiceClass="text-green-200"
              />
              <ContactListSupplierAnalytic
                analyticsClass="w-full lg:w-4/12"
                title="Total unpaid"
                total={getLengthByStatus(INVOICE_STATUS.INVOICE_UNPAID)}
                percent={getPercentByStatus(INVOICE_STATUS.INVOICE_UNPAID)}
                currency={currency}
                isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
                price={addCommas(totalAmountCurrency?.Unpaid?.toFixed(2))}
                rootClass="bg-yellow-500 mr-2"
                titleClass="text-blue-100"
                totalClass="text-blue-100"
                invoiceClass="text-gray-700"
              />
              <ContactListSupplierAnalytic
                analyticsClass="w-full lg:w-4/12"
                title="Draft"
                total={getLengthByStatus(INVOICE_STATUS.INVOICE_DRAFT)}
                percent={getPercentByStatus(INVOICE_STATUS.INVOICE_DRAFT)}
                currency={currency}
                isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
                price={addCommas(totalAmountCurrency?.Draft?.toFixed(2))}
                rootClass="bg-gray-400 mr-2"
                titleClass="text-gray-800"
                totalClass="text-blue-100"
                invoiceClass="text-gray-700"
              />{' '}
            </div>
          ) : (
            <div className="flex lg:flex-row flex-col gap-2 mb-6">
              <ContactListSupplierAnalytic
                title="Total paid"
                analyticsClass="w-full lg:w-4/12"
                total={getLengthByStatus(INVOICE_STATUS.INVOICE_PAID)}
                percent={getPercentByStatus(INVOICE_STATUS.INVOICE_PAID)}
                currency={currency}
                isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
                price={addCommas(totalAmountCurrency?.Paid?.toFixed(2))}
                rootClass="bg-green-500 mr-2 "
                titleClass="text-green-200"
                amountColor="text-white"
                totalClass="text-white"
                invoiceClass="text-green-200"
              />
              <ContactListSupplierAnalytic
                analyticsClass="w-full lg:w-4/12"
                title="Total unpaid"
                total={getLengthByStatus(INVOICE_STATUS.INVOICE_UNPAID)}
                percent={getPercentByStatus(INVOICE_STATUS.INVOICE_UNPAID)}
                currency={currency}
                isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
                price={addCommas(totalAmountCurrency?.Unpaid?.toFixed(2))}
                rootClass="bg-yellow-500 mr-2"
                titleClass="text-blue-100"
                totalClass="text-blue-100"
                invoiceClass="text-gray-700"
              />
              <ContactListSupplierAnalytic
                analyticsClass="w-full lg:w-4/12"
                title="Total"
                total={tableData?.length}
                percent={getPercentByStatus(INVOICE_STATUS.INVOICE_TOTAL)}
                currency={currency}
                isAmountHuge={findTotalCharacter(currency, totalAmountCurrency?.TotalAmount?.toFixed(2))}
                price={addCommas(totalAmountCurrency?.TotalAmount?.toFixed(2))}
                rootClass="bg-white"
                titleClass="text-blue-100"
                amountColor="text-blue-100"
                totalClass="text-blue-100"
                invoiceClass="text-gray-700"
              />
            </div>
          )}

          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={filterStatus}
              onChange={onFilterStatus}
              sx={{ px: 2, bgcolor: 'background.white' }}
            >
              {TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  icon={
                    <Label color={tab.color} variant={tab.variant}>
                      {' '}
                      {tab.count}{' '}
                    </Label>
                  }
                  sx={{
                    '&.MuiButtonBase-root': {
                      marginRight: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '0px' : '40px',
                      minWidth: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '45px' : '40px'
                    }
                  }}
                  value={tab.value}
                  label={
                    width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
                      ? filterStatus === tab?.label && tab?.label
                      : tab?.label
                  }
                />
              ))}
            </Tabs>

            <Divider className="mb-2" />

            <Scrollbar>
              <TableContainer
                sx={{ minWidth: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 300 : 700, position: 'relative' }}
              >
                {selected?.length > 0 && (
                  <TableSelectedActions
                    dense={dense}
                    numSelected={selected.length}
                    rowCount={tableData?.length}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row.id)
                      )
                    }
                    actions={
                      <Stack spacing={1} direction="row">
                        <Tooltip title="Sent">
                          <IconButton color="primary">
                            <Iconify icon={'ic:round-send'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Download">
                          <IconButton color="primary">
                            <Iconify icon={'eva:download-outline'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Print">
                          <IconButton color="primary">
                            <Iconify icon={'eva:printer-fill'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                            <Iconify icon={'eva:trash-2-outline'} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    }
                  />
                )}

                <Table>
                  {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData?.length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />
                  )}

                  <TableBody>
                    {dataFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <ViewContactTableRow
                        width={width}
                        key={row.id}
                        row={row}
                        selected={selected.includes(row._id)}
                        onSelectRow={() => onSelectRow(row._id)}
                        onDuplicateRow={() => onDuplicateRowHandler(row._id)}
                        onViewRow={() => handleViewRow(row._id)}
                        onArchiveRow={() => onArchiveRow(row._id)}
                        onDeleteRow={() => onDeleteRow(row._id)}
                        onEditRow={() => handleEditRow(row._id)}
                      />
                    ))}

                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[
                  ROW_PER_PAGE_OPTIONS.FIVE,
                  ROW_PER_PAGE_OPTIONS.TEN,
                  ROW_PER_PAGE_OPTIONS.TWENTY_FIVE
                ]}
                component="div"
                count={dataFiltered?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        </>
      )}
      {!isLoading && tableData?.length === 0 ? (
        contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS ? (
          <ContactStartedBanner contactData={getContactDetails} />
        ) : (
          <ContactSupplierBanner contactData={getContactDetails} />
        )
      ) : (
        ''
      )}
    </>
  );
}

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate
}) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    tableData = tableData?.filter(
      (item) =>
        item.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.user?.firstName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== INVOICE_STATUS.INVOICE_TOTAL) {
    tableData = tableData?.filter((item) => item.status === filterStatus);
  }

  if (filterStartDate && filterEndDate) {
    tableData = tableData?.filter(
      (item) =>
        getTimeDate(item.dateCreated) >= filterStartDate.getTime() &&
        getTimeDate(item.dateCreated) <= filterEndDate.getTime()
    );
  }

  return tableData;
}

const mapStateToProps = (state) => {
  return {
    getContactDetails: state?.contactReducer?.viewContactDetails?.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInvoiceContactList: (contactId) => dispatch(contactActions.getInvoiceContactList(contactId)),
    createDuplicateInvoice: (deleteInvoiceData) => dispatch(invoiceActions.createInvoice(deleteInvoiceData)),
    getInvoiceDetails: (invoiceId) => dispatch(invoiceActions.getInvoiceDetails(invoiceId)),
    invoiceCount: (invoiceId) => dispatch(invoiceActions.invoiceCount(invoiceId)),
    invoiceCurrency: (invoiceData) => dispatch(invoiceActions.invoiceCurrency(invoiceData)),
    getCurrencyPreference: (payload) => dispatch(profileActions.getCurrencyPreference(payload)),
    getInvoiceSupplierList: (supplierId) => dispatch(importingInvoiceActions.getImportingInvoiceList(supplierId)),
    deleteImportedInvoice: (supplierId) => dispatch(importingInvoiceActions.deleteImportedInvoice(supplierId)),
    getImportingDashboardData: (id) => dispatch(importingInvoiceActions.getImportingDashboardData(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewContactList);

ViewContactList.propTypes = {
  getInvoiceContactList: PropTypes.func,
  createDuplicateInvoice: PropTypes.func,
  getInvoiceDetails: PropTypes.func,
  invoiceCount: PropTypes.func,
  invoiceCurrency: PropTypes.func,
  getContactDetails: PropTypes.any,
  getCurrencyPreference: PropTypes.func,
  getInvoiceSupplierList: PropTypes.func,
  deleteImportedInvoice: PropTypes.func,
  getImportingDashboardData: PropTypes.func
};
