import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import MSText from '../ui/typography/MSText';
import Avatar from '../ui/Avatar';
import { stringTruncate } from '../../helper/helpers';
import { SESSION_STORAGE_NAME, STRING_MAX_CHAR, SVG_TYPES } from '../../constants/enum';
import { Divider } from '@mui/material';
import '../../styles/headerNavigation.scss';
import Badge from '../companyList/Badge';
import Tick from '../../images/icons/check-box-tick.svg';
import { connect } from 'react-redux';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import { getSvgIcon } from '../../helper/svgHelper';
import { RENEWAL_STATUS } from '../../constants/enum';
import { EXTERNAL_LINKS } from '../../constants/enum';
import SkeletonRectangle from '../common/ReactangleSkeleton';
function NavigationDropDownLeft({ companyList }) {
  const [onHover, setOnHover] = useState();
  const [openBussinessAccountList, setOpenBussinessAccountList] = useState(false);
  const [openInvoicingList, setOpenInvoicingList] = useState(false);
  const [hide, setHide] = useState(false);
  const switchCompany = async () => {};
  const handleNewCompanyCreation = () => {
    navigate(`${PATH_PAGE.signUp}/start`);
  };
  const handleSwitchToStatrys = () => {
    navigate(`${EXTERNAL_LINKS?.SWITCH_TO_STATRYS}`);
  };
  const handleCompanyExpiring = () => {};
  return (
    <>
      <div className={`absolute dropdown-content-container-left left-2 sm:left-[25px]`}>
        <div className="dropdown-content">
          <div className="tooltip-container">
            <div className="tooltip-icon " style={{ position: 'absolute', left: '16px' }}></div>
          </div>
          <div
            className="dropdown-content-container pb-6 px-2 max-h-[600px] w-[339px] max-w-[95vw] overflow-scroll flex flex-col"
            onMouseLeave={() => setOnHover()}
          >
            {/* Invoice List */}
            <div className="px-4 flex flex-col gap-3 mt-2">
              <SkeletonRectangle />
              <SkeletonRectangle />
              <SkeletonRectangle />
            </div>

            {hide && (
              <div
                className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 mt-2 ${
                  !onHover && 'hover:bg-gray-100'
                } `}
                onClick={() => handleNewCompanyCreation()}
              >
                <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                <MSText title="Manage your invoices" textColor="text-blue-300" className="dropdown-email-text ml-2" />
              </div>
            )}
            {/* Business Account */}
            <div>
              <div
                className={`flex flex-row items-center rounded-lg px-4 py-4 mt-3 ${!onHover && 'hover:bg-gray-100'} `}
                onClick={() => {
                  setOpenBussinessAccountList((prev) => !prev);
                  setOpenInvoicingList(false);
                }}
              >
                <div>{getSvgIcon(SVG_TYPES.BUSINESS_ACCOUNT)}</div>
                <div className="flex justify-between items-center flex-grow">
                  <MSText title="Business Account" textColor="text-blue-300" className="dropdown-email-text ml-2" />
                  {openBussinessAccountList && getSvgIcon(SVG_TYPES.ARROW_UP)}
                  {!openBussinessAccountList && getSvgIcon(SVG_TYPES.ARROW_DOWN)}
                </div>
              </div>
              {openBussinessAccountList && (
                <>
                  <div className="px-4 flex flex-col gap-3 mt-2">
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                  </div>
                  <div
                    className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 mt-2 ${
                      !onHover && 'hover:bg-gray-100'
                    } `}
                    onClick={() => handleNewCompanyCreation()}
                  >
                    <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                    <MSText
                      title="Apply for a Business Account"
                      textColor="text-blue-300"
                      className="dropdown-email-text ml-2"
                    />
                  </div>
                </>
              )}
            </div>
            {/* Company Management */}
            <div>
              <div
                className={`flex flex-row items-center rounded-lg px-4 py-4  ${!onHover && 'hover:bg-gray-100'} `}
                onClick={() => {
                  setOpenBussinessAccountList(false);
                  setOpenInvoicingList((prev) => !prev);
                }}
              >
                <div>{getSvgIcon(SVG_TYPES.INCORPORATION)}</div>
                <div className="flex justify-between items-center flex-grow">
                  <MSText title="Company Management" textColor="text-blue-300" className="dropdown-email-text ml-2" />
                  {openInvoicingList && getSvgIcon(SVG_TYPES.ARROW_UP)}
                  {!openInvoicingList && getSvgIcon(SVG_TYPES.ARROW_DOWN)}
                </div>
              </div>
              {openInvoicingList && (
                <>
                  <div className="px-4 flex flex-col gap-3 mt-2 mb-3">
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                  </div>

                  {/* Register another company */}
                  <div
                    className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${
                      !onHover && 'hover:bg-gray-100'
                    } `}
                    onClick={() => handleNewCompanyCreation()}
                  >
                    <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                    <MSText
                      title="Register another company"
                      textColor="text-blue-300"
                      className="dropdown-email-text ml-2"
                    />
                  </div>
                  {/* Switch to  statrys */}
                  <div
                    className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${
                      !onHover && 'hover:bg-gray-100'
                    } `}
                    onClick={() => handleSwitchToStatrys()}
                  >
                    <div>{getSvgIcon(SVG_TYPES.SWITCH_TO_STATRYS)}</div>
                    <MSText title="Switch to Statrys" textColor="text-blue-300" className="dropdown-email-text ml-2" />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    toSwitchCompany: (companyPayload) => dispatch(CompanyAction.switchCompany(companyPayload))
  };
};
export default connect(null, mapDispatchToProps)(NavigationDropDownLeft);
NavigationDropDownLeft.propTypes = {
  menuItems: PropTypes.array,
  needSeparator: PropTypes.bool,
  separtorPositions: PropTypes.array,
  children: PropTypes.any
};
