import React from 'react';

const SkeletonRectangle = ({ width = 'w-full', height = 'h-12', borderRadius = 'rounded-lg' }) => {
  return (
    <div className={`bg-customBg-mainBg relative overflow-hidden ${width} ${height} ${borderRadius}`}>
      <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent via-gray-200 to-transparent animate-skeleton"></div>
    </div>
  );
};

export default SkeletonRectangle;
