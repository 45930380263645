import React, { useState, useEffect } from 'react';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
import { navigate } from 'gatsby';
import {
  API_CODE,
  CONTACT_TABLE_TITLE,
  INVOICE_STATUS,
  MUI_COLORS,
  RESPONSIVENESS_BREAKPOINT_VALUE,
  ROW_PER_PAGE_OPTIONS
} from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Tooltip,
  Divider,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination
} from '@mui/material';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { TableNoData, TableEmptyRows, TableHeadCustom, TableSelectedActions } from '../table';
import ContactTableRow from '../../sections/@contact/contact-list/ContactTableRow';
import XSText from '../ui/typography/XSText';
import Snackbar from '../Snackbar';
import Image from '../Image';
import Success from '../../images/icons/success-delete-icon.svg';
import MSText from '../ui/typography/MSText';
import { connect, useSelector } from 'react-redux';
import TableStatrysLoader from '../../components/table/TableStatrysLoader';
import * as contactActions from '../../components/contact/reduxStore/action';
import * as supplierActions from '../../components/supplier/reduxStore/action';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Contact({ allList, deleteContact, contactAllList, loading, deleteSupplier }) {
  const isLoading = useSelector((state) => state.contactReducer?.loading);
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({ defaultOrderBy: 'dateCreated' });
  const [tableData, setTableData] = useState(allList);
  const [deletedResponse, setDeletedResponse] = useState('');
  const [error, setError] = useState(false);
  const [deletedMessage, setDeletedMessage] = useState({
    content: ''
  });
  const { width } = useWindowDimensions();

  const TABLE_HEAD = [
    {
      id: 'companyName',
      label: 'Name',
      align: 'left',
      width: 340,
      pl: 9
    },
    {
      id: 'relation',
      label: 'Relation',
      align: 'left',
      width: 340
    },
    { id: 'country', label: 'Country', align: 'left', width: 185 },
    { id: 'sent', label: 'Invoices', align: 'left', width: 185 },
    { id: 'totalPrice', label: 'Amount', align: 'left', width: 185 },
    { id: '', width: 100 }
  ];

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteRow = (id, firstName, companyName, contactType) => {
    if (contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS) {
      handleDeleteClient(id, firstName, companyName);
    }
    if (contactType === CONTACT_TABLE_TITLE.ONLY_SUPPLIERS) {
      handleDeleteSupplier(id, firstName, companyName);
    }
  };

  const handleDeleteClient = async (id, firstName, companyName) => {
    const requestedData = {
      contactId: id
    };
    const deletedContactResponse = await deleteContact({ params: { ...requestedData } });
    if (deletedContactResponse?.status === API_CODE.STATUS_200) {
      setDeletedResponse(deletedContactResponse?.data?.data);
      setDeletedMessage({
        ...deletedMessage,
        contentMessage: `Invoice ${companyName ? companyName : firstName} was successfully deleted!`
      });
      contactAllList();
      setOpen(true);
    } else {
      setError(true);
    }
  };

  const handleDeleteSupplier = async (id, firstName, companyName) => {
    const deletedSupplierResponse = await deleteSupplier(id);
    if (deletedSupplierResponse?.status === API_CODE.STATUS_200) {
      setDeletedResponse(deletedSupplierResponse?.data?.data);
      setDeletedMessage({
        ...deletedMessage,
        contentMessage: `Invoice ${companyName ? companyName : firstName} was successfully deleted!`
      });
      contactAllList();
      setOpen(true);
    } else {
      setError(true);
    }
  };

  const getLengthByStatus = (status) => tableData?.filter((item) => item.status === status).length;

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData?.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id, contactData) => {
    navigate(PATH_PAGE.viewContact + `?id=${id}`, { state: { contactType: contactData?.contactType } });
  };

  const handleViewRow = (id, contactData) => {
    navigate(`${PATH_PAGE.viewContact}?id=${id}`, { state: { contactType: contactData?.contactType } });
  };

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs(CONTACT_TABLE_TITLE.ALL);
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterStatus
  });

  const isNotFound = !dataFiltered?.length && !!filterStatus;

  const TABS = [
    {
      value: CONTACT_TABLE_TITLE.ALL,
      label: CONTACT_TABLE_TITLE.ALL,
      color: 'info',
      count: tableData?.length
    },
    {
      value: CONTACT_TABLE_TITLE.ONLY_CLIENTS,
      label: `Only ${CONTACT_TABLE_TITLE.ONLY_CLIENTS}`,
      color: 'success',
      count: getLengthByStatus(CONTACT_TABLE_TITLE.ONLY_CLIENTS)
    },
    {
      value: CONTACT_TABLE_TITLE.ONLY_SUPPLIERS,
      label: `Only ${CONTACT_TABLE_TITLE.ONLY_SUPPLIERS}`,
      color: 'success',
      count: getLengthByStatus(CONTACT_TABLE_TITLE.ONLY_SUPPLIERS)
    }
    // {
    //   value: CONTACT_TABLE_TITLE.DELETED,
    //   label: CONTACT_TABLE_TITLE.DELETED,
    //   color: 'warning',
    //   count: getLengthByStatus(CONTACT_TABLE_TITLE.DELETED)
    // }
  ];

  const handleTabChange = (e, value) => {
    if (value !== INVOICE_STATUS.INVOICE_TOTAL) {
      onChangePage(e, 0);
    }
    onFilterStatus(e, value);
  };

  useEffect(() => {
    setTableData(allList);
  }, [allList]);

  return (
    <>
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterStatus}
          onChange={handleTabChange}
          sx={{ px: 2, bgcolor: 'background.white' }}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              value={tab.value}
              label={tab.label}
              sx={{
                '&.MuiButtonBase-root': {
                  marginRight: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '15px' : '40px',
                  minWidth: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '40px' : '45px'
                }
              }}
            />
          ))}
        </Tabs>

        <Divider className="mb-2" />
        <Scrollbar>
          <TableContainer
            sx={{ minWidth: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 300 : 800, position: 'relative' }}
          >
            {selected?.length > 0 && (
              <TableSelectedActions
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData?.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData?.map((row) => row._id)
                  )
                }
                actions={
                  <Stack spacing={1} direction="row">
                    <Tooltip title="Sent">
                      <IconButton color="primary">
                        <Iconify icon={'ic:round-send'} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Download">
                      <IconButton color="primary">
                        <Iconify icon={'eva:download-outline'} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Print">
                      <IconButton color="primary">
                        <Iconify icon={'eva:printer-fill'} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                }
              />
            )}

            <Table>
              {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />
              )}
              {isLoading ? (
                <TableStatrysLoader />
              ) : (
                <TableBody>
                  {dataFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <ContactTableRow
                      width={width}
                      key={row.id}
                      row={row}
                      selected={selected.includes(row._id)}
                      onSelectRow={() => onSelectRow(row._id)}
                      onViewRow={() => handleViewRow(row._id, row)}
                      onEditRow={() => handleEditRow(row._id, row)}
                      onDeleteRow={() => handleDeleteRow(row._id, row.firstName, row.companyName, row?.contactType)}
                    />
                  ))}
                  <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />
                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[
              ROW_PER_PAGE_OPTIONS.TEN,
              ROW_PER_PAGE_OPTIONS.TWENTY_FIVE,
              ROW_PER_PAGE_OPTIONS.FIFTY
            ]}
            component="div"
            count={dataFiltered?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Box>

        <Snackbar
          open={open}
          handleClose={handleClose}
          sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
          title={
            <div className="flex">
              <div className="flex items-center">
                <Image className="w-5 h-5" src={Success} />
              </div>
              <div className="ml-4">
                <XSText fontWeight="text-bold" textColor="text-gray-500" title={deletedResponse.customMessage}></XSText>
                <MSText textColor="text-gray-500" title={deletedMessage.contentMessage}></MSText>
              </div>
            </div>
          }
        ></Snackbar>
      </Card>
    </>
  );
}

function applySortFilter({ tableData, comparator, filterStatus }) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el) => el[0]);

  if (filterStatus !== CONTACT_TABLE_TITLE.ALL) {
    // if (filterStatus == CONTACT_TABLE_TITLE.ACTIVE) {
    //   tableData = tableData?.filter((item) => item.isDeleted === false);
    // } else {
    //   tableData = tableData?.filter((item) => item.deleted === true);
    // }
    if (filterStatus !== CONTACT_TABLE_TITLE.ALL) {
      tableData = tableData?.filter((item) => item.contactType === filterStatus);
    }
  }

  return tableData;
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteContact: (clientId) => dispatch(contactActions.deleteContact(clientId)),
    deleteSupplier: (supplierId) => dispatch(supplierActions.deleteSupplier(supplierId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);

Contact.propTypes = {
  allList: PropTypes.array,
  deleteContact: PropTypes.func,
  contactAllList: PropTypes.func,
  loading: PropTypes.bool,
  deleteSupplier: PropTypes.func
};
