import React from 'react';
import { Snackbar, Alert, Container } from '@mui/material';
import PropTypes from 'prop-types';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { RESPONSIVENESS_BREAKPOINT_VALUE } from '../constants/enum';
function AlertComponenet(props) {
  const { open, handleClose, title, sx, icon, autoHideDuration } = props;
  const { width } = useWindowDimensions();

  return (
    <div>
      {' '}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={autoHideDuration}
        transitionDuration={{ enter: 1000, exit: 5000 }}
        TransitionProps={{ enter: true, exit: false }}
        sx={{
          width: '100%',
          marginRight: '8px',
          marginBottom: '112px',
          left: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '0px' : '8px',
          right: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '0px' : '8px'
        }}
        onClose={handleClose}
      >
        <Alert
          className="snackbar"
          onClose={handleClose}
          icon={icon ?? false}
          sx={{
            width: width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '364px' : '100%',
            alignItems: 'center',
            borderRadius: '8px',
            ...sx
          }}
        >
          {title}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AlertComponenet;

AlertComponenet.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.any,
  autoHideDuration: PropTypes.number
};

AlertComponenet.defaultProps = {
  autoHideDuration: 90000
};
