import React from 'react';

function Invoicing() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#FFC730" />
      <path
        d="M16.8551 32.0332C17.2786 31.9508 17.5552 31.5407 17.4729 31.1172C17.3905 30.6936 16.9804 30.4171 16.5569 30.4994L16.8551 32.0332ZM13.8377 31.7355L13.7334 30.9613L13.8377 31.7355ZM9.06728 25.8381L8.39737 26.24L9.06728 25.8381ZM9.69842 23.3302L9.29857 22.6591L9.69842 23.3302ZM8.39737 26.24L11.4239 31.2843L12.7638 30.4804L9.7372 25.4361L8.39737 26.24ZM13.9419 32.5098C14.6857 32.4096 15.7216 32.2536 16.8551 32.0332L16.5569 30.4994C15.4609 30.7125 14.4562 30.8639 13.7334 30.9613L13.9419 32.5098ZM13.9821 19.8689L9.29857 22.6591L10.0983 24.0014L14.7818 21.2112L13.9821 19.8689ZM11.4239 31.2843C11.9418 32.1474 12.9205 32.6473 13.9419 32.5098L13.7334 30.9613C13.3543 31.0123 12.973 30.8292 12.7638 30.4804L11.4239 31.2843ZM9.7372 25.4361C9.43975 24.9404 9.60159 24.2973 10.0983 24.0014L9.29857 22.6591C8.05889 23.3976 7.65495 25.0027 8.39737 26.24L9.7372 25.4361Z"
        fill="#E15741"
      />
      <path
        d="M29.7293 8.99951H16.3406C15.3322 8.99951 14.5148 9.81692 14.5148 10.8253V29.4559C14.5148 30.2063 14.304 30.9416 13.9062 31.5779C15.1187 31.5375 16.3215 31.347 17.4872 31.0107L30.2354 27.3334C31.0169 27.1079 31.5551 26.3926 31.5551 25.5791V10.8253C31.5551 9.81692 30.7377 8.99951 29.7293 8.99951Z"
        stroke="#E15741"
        stroke-width="1.5625"
      />
    </svg>
  );
}

export default Invoicing;
