import * as React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Checkbox, TableRow, TableCell, Typography, Stack, Link, MenuItem } from '@mui/material';
// utils
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import { TableMoreMenu } from '../../../components/table';
import {
  CONSTANT_NUMBER,
  CONTACT_TABLE_TITLE,
  MUI_COLORS,
  REGEX,
  RESPONSIVENESS_BREAKPOINT_VALUE,
  STRING_MAX_CHAR
} from '../../../constants/enum';
import Avatar from '../../../components/ui/Avatar';
import { stringTruncate } from '../../../helper/helpers';

// ----------------------------------------------------------------------

ContactTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func
};

export default function ContactTableRow({ row, selected, onSelectRow, onViewRow, onDeleteRow, onEditRow, width }) {
  const theme = useTheme();

  const { country, firstName, lastName, companyName, profileImagePublic, invoiceCount, contactType, amount } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const amountWithoutCurrency = Number(amount?.replace(REGEX.GET_NUMBER_WITH_DECIMAL, ''));
  const currencySymbol = amount?.replace(REGEX.GET_VALUE_WITHOUT_DECIMAL_INT, '');

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          padding: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '16px 4px 16px -10px !important' : '16px',
          '&.MuiTableCell-root:first-of-type': {
            paddingLeft: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '8px' : '24px'
          }
        }}
        onClick={onViewRow}
      >
        <div className="mr-2">
          <Avatar
            avatarImage={profileImagePublic}
            avatarName={firstName ? firstName?.charAt(0) : companyName?.charAt(0)}
          />
        </div>
        <Stack>
          <Typography
            sx={{ color: MUI_COLORS.MID_NIGHT, textDecoration: 'none', boxShadow: 'none' }}
            variant="subtitle2"
            noWrap
          >
            {width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
              ? stringTruncate(firstName ? `${firstName} ${lastName}` : companyName, CONSTANT_NUMBER.THIRTEEN)
              : stringTruncate(firstName ? `${firstName} ${lastName}` : companyName, STRING_MAX_CHAR.TWENTY_FIVE)}
          </Typography>
          <Link noWrap variant="body2" onClick={onViewRow} sx={{ color: 'text.disabled', cursor: 'pointer' }}>
            {width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
              ? stringTruncate(firstName && companyName, CONSTANT_NUMBER.THIRTEEN)
              : stringTruncate(firstName && companyName, STRING_MAX_CHAR.TWENTY_FIVE)}
          </Link>
        </Stack>
      </TableCell>
      <TableCell
        align={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'right' : 'left'}
        onClick={onViewRow}
        sx={{
          cursor: 'pointer',
          padding: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '16px 4px 16px 4px' : '16px'
        }}
      >
        <Stack>
          <div>{contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS ? 'Client' : 'Supplier'}</div>
          {width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && `${currencySymbol + amountWithoutCurrency?.toFixed(2)}`}
        </Stack>
      </TableCell>
      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell align="left" onClick={onViewRow} sx={{ cursor: 'pointer' }}>
          {country}
        </TableCell>
      )}
      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell align="left" onClick={onViewRow} sx={{ textTransform: 'capitalize', cursor: 'pointer' }}>
          {invoiceCount}
        </TableCell>
      )}

      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell
          onClick={onViewRow}
          sx={{
            cursor: 'pointer',
            padding: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '16px 4px 16px 4px' : '16px'
          }}
          align="left"
        >
          {currencySymbol + amountWithoutCurrency?.toFixed(2)}
        </TableCell>
      )}
      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  className="pl-6"
                  onClick={() => {
                    onViewRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:eye-fill'} />
                  View
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
                <MenuItem
                  className="pl-6"
                  onClick={() => {
                    onDeleteRow();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
              </>
            }
          />
        </TableCell>
      )}
    </TableRow>
  );
}
