import React, { useState } from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import TextInput from '../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../ui/ValidationMessage';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import SelectInputAutoComplete from '../ui/inputs/selectInputAutoComplete';
import * as commonReduxActions from '../commonReduxStore/reduxStore/action';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_CODE, FILE_UPLOAD_TYPE, INVOICE_STATUS, MUI_COLORS, PAYMENT_STATUS_INVOICE } from '../../constants/enum';
import * as incomingInvoiceReducer from './reduxStore/action';
import moment from 'moment';
import { InputAdornment } from '@mui/material';
import { PATH_PAGE } from '../../routes/paths';
import * as importingInvoiceReducer from '../importedInvoices/reduxStore/action';
import { getUTCBasedDate } from '../../utils/formatTime';
import UploadDocument from '../importedInvoices/UploadDocument';
import { getFileName } from '../../helper/helpers';

const options = [
  {
    name: 'Cash'
  },
  {
    name: 'Cheque'
  },
  {
    name: 'Credit Card'
  },
  {
    name: 'Bank Transfer'
  },
  {
    name: 'PayPal'
  },
  {
    name: 'PayMe'
  }
];
function AddPaymentModal({
  openDynamicModal,
  closeDynamicModal,
  setHandlerInvoiceIsPaid,
  importedInvoiceData,
  updateMarkAsPaid,
  updateImportingInvoice,
  getImportingInvoiceDetails,
  markAsPaid,
  isEdit,
  getMarkAsPaidData,
  updatePayment,
  uploadFile
}) {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      paidDate: getMarkAsPaidData?.paidDate
    }
  });
  const [paymentType, setPaymentType] = useState('');
  const [saveImage, setSaveImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [paymentProofDoc, setPaymentProofDoc] = useState('');
  const [isAmountValid, setIsAmountValid] = useState(false);

  const handleToEmptyState = () => {
    closeDynamicModal();
    setAcceptedFile('');
    setSaveImage(false);
    setSelectedImage('');
    setPaymentType('');
    setIsPayment(false);
    setIsLoading(false);
  };

  const handleCheckValidAmount = (e) => {
    if (importedInvoiceData?.payments?.length > 0) {
      if (Number(e.target.value) <= Number(importedInvoiceData?.pendingAmount)) {
        setIsAmountValid(false);
      } else {
        setIsAmountValid(true);
      }
    } else {
      if (Number(e.target.value) <= Number(importedInvoiceData?.totalAmount)) {
        setIsAmountValid(false);
      } else {
        setIsAmountValid(true);
      }
    }
  };

  const handlePaymentType = (value) => {
    setPaymentType(value);
    setIsPayment(false);
  };

  const onSubmit = (data) => {
    if (isEdit) {
      setIsLoading(true);
      updateMarkAsPaidHandler(data);
    } else {
      if (!paymentType) {
        setIsPayment(true);
      } else {
        setIsLoading(true);
        markAsPaidHandler(data);
      }
    }
  };

  const remaining = importedInvoiceData?.payments?.reduce((accumulator, object) => {
    return accumulator + object.paidAmount;
  }, 0);

  const updateImportingInvoiceHandler = async (id, markAsPaid, remainingAmount) => {
    const payload = {
      invoiceId: id,
      status:
        markAsPaid?.paymentStatus === PAYMENT_STATUS_INVOICE.PARTIAL_PAID
          ? INVOICE_STATUS.PARTIALLY_PAID
          : INVOICE_STATUS.INVOICE_PAID,
      publicURL: `${process.env.SITE_URL}${PATH_PAGE.incomingPublicInvoice}?id=${id}`,
      pendingAmount: isEdit
        ? markAsPaid?.totalAmount - remainingAmount
        : markAsPaid?.totalAmount - (remaining + Number(remainingAmount))
    };
    const getUpdatedInvoiceResponse = await updateImportingInvoice(payload);
    if (getUpdatedInvoiceResponse?.status === API_CODE?.STATUS_200) {
      getImportingInvoiceDetails();
      setHandlerInvoiceIsPaid();
      handleToEmptyState();
    }
  };

  const updateMarkAsPaidHandler = async (data) => {
    const payload = {
      paidDate: data.paidDate,
      paymentType: paymentType?.name,
      paidAmount: isEdit
        ? updatePayment?.paidAmount
        : importedInvoiceData?.pendingAmount
        ? importedInvoiceData?.pendingAmount
        : importedInvoiceData?.totalAmount,
      invoiceId: importedInvoiceData?._id,
      paymentId: updatePayment?._id,
      documentCode: paymentProofDoc
    };
    const markAsPaidResponse = await updateMarkAsPaid(payload);
    if (markAsPaidResponse?.status === API_CODE.STATUS_200) {
      const remaining = markAsPaidResponse?.data?.data?.payments?.reduce((accumulator, object) => {
        return accumulator + object.paidAmount;
      }, 0);
      if (remaining) {
        updateImportingInvoiceHandler(importedInvoiceData?._id, markAsPaidResponse?.data?.data, remaining);
      }
    }
  };

  const markAsPaidHandler = async (data) => {
    const payload = {
      paidDate: data.paidDate,
      paymentType: paymentType?.name,
      paidAmount: isEdit
        ? updatePayment?.paidAmount
        : importedInvoiceData?.pendingAmount
        ? importedInvoiceData?.pendingAmount
        : importedInvoiceData?.totalAmount,
      invoiceId: importedInvoiceData?._id,
      documentCode: paymentProofDoc
    };
    const markAsPaidResponse = await markAsPaid(payload);
    if (markAsPaidResponse?.status === API_CODE.STATUS_200) {
      updateImportingInvoiceHandler(importedInvoiceData?._id, markAsPaidResponse?.data?.data, data.amount);
    }
  };

  const fileSelection = async (acceptedFiles) => {
    setAcceptedFile(acceptedFiles);
    let selectedimg = acceptedFiles?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setSelectedImage(selectedimg);
    const formData = new FormData();
    formData.append('file', selectedimg[0]);
    const uploadDocument = await uploadFile(formData);
    if (uploadDocument?.status === API_CODE.STATUS_200) {
      setPaymentProofDoc(uploadDocument?.data?.data?.key);
    }
    setSaveImage(true);
  };

  const handleRemoveFile = (file) => {
    const remainingFiles = selectedImage.filter((item) => item.name !== file.name);
    setSelectedImage('');
    setAcceptedFile('');
  };
  return (
    <div>
      <DynamicModal openDynamicModal={openDynamicModal} closeDynamicModal={closeDynamicModal} minWidth="520px">
        <div className="mt-5 md:px-10">
          <MDSubtitleText className="flex justify-center mb-10" title={`Record payment`} fontWeight="text-bold" />
          <form id="add-invoice-payment" onSubmit={handleSubmit(onSubmit)}>
            <SelectInputAutoComplete
              name="paymentType"
              defaultValue={isEdit ? updatePayment?.paymentType : paymentType}
              setSelectedValue={handlePaymentType}
              disableClearable={true}
              label="Payment type"
              options={options}
              inputRef={register({
                required: true
              })}
              error={isPayment ? true : false}
              helperText={isPayment && <ValidationMessage title={'Please provide a payment type'} />}
            />
            <TextInput
              defaultValue={
                updatePayment?.paidDate
                  ? getUTCBasedDate(new Date(updatePayment?.paidDate), 'UTC')
                  : moment(new Date()).format('YYYY-MM-DD')
              }
              name="paidDate"
              label="Date"
              type="date"
              textFieldContainerClass="mt-4"
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register({
                required: true
              })}
              error={errors?.paidDate ? true : false}
              helperText={errors?.paidDate && <ValidationMessage title={'Please provide the date'} />}
            />
            <TextInput
              defaultValue={
                isEdit
                  ? updatePayment?.paidAmount
                  : importedInvoiceData?.pendingAmount
                  ? importedInvoiceData?.pendingAmount
                  : importedInvoiceData?.totalAmount
              }
              startAdornment={
                <InputAdornment position="start">
                  {importedInvoiceData?.currency?.symbol
                    ? importedInvoiceData?.currency?.symbol
                    : importedInvoiceData?.currency?.name}
                </InputAdornment>
              }
              name="amount"
              label="Amount"
              backgroundColor={MUI_COLORS.GRAY_FOR_DISABLE_INPUTS}
              textFieldContainerClass="mt-4"
              inputRef={register({
                required: false
              })}
              disabled={true}
              onChange={handleCheckValidAmount}
              error={errors?.amount ? true : isAmountValid ? isAmountValid : false}
              helperText={
                errors?.amount ? (
                  <ValidationMessage title={'Please provide the amount'} />
                ) : (
                  isAmountValid && (
                    <ValidationMessage title={'The amount recorded cannot be higher than the invoice amount'} />
                  )
                )
              }
            />
            <UploadDocument
              saveImage={saveImage}
              isEdit={isEdit}
              selectedImage={
                isEdit && updatePayment?.paymentProofSignedUrl
                  ? [
                      {
                        preview: updatePayment?.paymentProofSignedUrl,
                        name: getFileName(updatePayment?.paymentProofSignedUrl)?.name,
                        type: FILE_UPLOAD_TYPE.URL
                      }
                    ]
                  : selectedImage
              }
              uploadError={uploadError}
              acceptedFile={
                isEdit && updatePayment?.paymentProofSignedUrl
                  ? getFileName(updatePayment?.paymentProofSignedUrl)
                  : acceptedFile?.[0]
              }
              fileSelection={fileSelection}
              handleRemoveFile={handleRemoveFile}
            />
            <div className="flex justify-center flex-row gap-4 mt-8 mb-4">
              <PrimaryButton
                id="add-payment-save-payment-btn"
                isLoading={isLoading}
                caption="Save Payment"
                type="small"
                className="modal-save-button"
                onClick={handleSubmit(onSubmit)}
                disabled={isAmountValid}
              />
              <PrimaryButton
                id="add-payment-cancel-btn"
                className="modal-cancel-button font-weight-bold border border-gray-400"
                bgColor="bg-white"
                color="text-gray-300"
                caption="Cancel"
                onClick={closeDynamicModal}
              />
            </div>
          </form>
        </div>
      </DynamicModal>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateMarkAsPaid: (payload) => dispatch(incomingInvoiceReducer.updateMarkAsPaid(payload)),
    markAsPaid: (payload) => dispatch(incomingInvoiceReducer.markAsPaid(payload)),
    updateImportingInvoice: (invoiceData) => dispatch(importingInvoiceReducer.editImportingInvoice(invoiceData)),
    uploadFile: (uploadedImage) => dispatch(commonReduxActions.uploadDocument(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(AddPaymentModal);

AddPaymentModal.propTypes = {
  openDynamicModal: PropTypes.bool,
  closeDynamicModal: PropTypes.any,
  setHandlerInvoiceIsPaid: PropTypes.any,
  importedInvoiceData: PropTypes.any,
  updateMarkAsPaid: PropTypes.func,
  updateImportingInvoice: PropTypes.func,
  getImportingInvoiceDetails: PropTypes.func,
  markAsPaid: PropTypes.func,
  isEdit: PropTypes.bool,
  uploadFile: PropTypes.func
};
