import React, { useState } from 'react';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import XSText from '../../ui/typography/XSText';
import TextInput from '../../ui/inputs/TextInput';
import BackButton from '../../ui/buttons/BackButton';
import PasswordMan from '../../../images/graphics/company-incorporation/set-password.png';
import { CHAR_CODE, CONSTANT_NUMBER, MUI_COLORS, REGEX } from '../../../constants/enum';
import { useForm, Controller } from 'react-hook-form';
import ValidationMessage from '../../ui/ValidationMessage';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import BaseText from '../../ui/typography/BaseText';
import CheckByStreamLineHq from '../../../images/icons/customSvg/checkByStreamLineHq';
import MSText from '../../ui/typography/MSText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
const Email = ({ prevStep, nextStep }) => {
  const { register, handleSubmit, errors, control } = useForm();
  const [inputValue, setInputValue] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const { email, password, confirmPassword } = inputValue;
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
  const [isPasswordNotFocused, setIsPasswordNotFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState();
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    confirmPassword: false
  });
  const emailId = sessionStorage.getItem('email');
  const onSubmit = async (data) => {
    if (isPasswordCriteriaValid() && passwordValidation.passwordMatch) {
      setLoading(true);
      let payload = {
        email: emailId.replace(/ /g, '+'),
        password: data?.password,
        companyId: sessionStorage.getItem('companyId')
      };
      const signupResponse = await axios.post(`${process.env.BASE_API_URL}invoice/signupIncorporation`, {
        ...payload
      });
      if (signupResponse?.data?.statusCode === 200) {
        nextStep();
      }
      setLoading(false);
    }
    if (!isPasswordCriteriaValid()) {
      setShowPasswordError(true);
    }
    if (!passwordValidation.passwordMatch) {
      setShowConfirmPasswordError(true);
    }
    if (passwordValidation.passwordMatch) {
      setShowConfirmPasswordError(false);
    }
  };
  const currentPasswordClicked = () => {
    setShowPassword({
      ...showPassword,
      currentPassword: !showPassword?.currentPassword
    });
  };
  const confirmPasswordClicked = () => {
    setShowPassword({
      ...showPassword,
      confirmPassword: !showPassword?.confirmPassword
    });
  };

  const [passwordValidation, setPasswordValidation] = useState({
    tenCharacters: false,
    upperCase: false,
    lowerCase: false,
    digit: false,
    specialCharacter: false,
    space: false,
    passwordMatch: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputValue((prev) => ({
      ...prev,
      [name]: value
    }));

    if (name === 'password') {
      if (value.length > CONSTANT_NUMBER.NINE) {
        setPasswordValidation((prev) => ({
          ...prev,
          tenCharacters: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          tenCharacters: false
        }));
      }

      let upperCaseRegex = REGEX.UPPER_CASE_REGEX;
      if (value.match(upperCaseRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          upperCase: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          upperCase: false
        }));
      }

      let lowerCaseRegex = REGEX.LOWER_CASE_REGEX;
      if (value.match(lowerCaseRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          lowerCase: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          lowerCase: false
        }));
      }

      let digitRegex = REGEX.DIGIT_CASE_REGEX;
      if (value.match(digitRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          digit: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          digit: false
        }));
      }

      let specialCharacterRegex = REGEX.SPECIAL_CASE_REGEX;
      if (value.match(specialCharacterRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          specialCharacter: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          specialCharacter: false
        }));
      }

      let spaceRegex = REGEX.SPACE_REGEX;
      if (!value.match(spaceRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          space: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          space: false
        }));
      }

      if (value === confirmPassword) {
        setPasswordValidation((prev) => ({
          ...prev,
          passwordMatch: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          passwordMatch: false
        }));
      }
    }
    if (name === 'confirmPassword') {
      if (value === password) {
        setPasswordValidation((prev) => ({
          ...prev,
          passwordMatch: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          passwordMatch: false
        }));
      }
    }

    if (
      value.length > CONSTANT_NUMBER.NINE &&
      passwordValidation.upperCase &&
      passwordValidation.lowerCase &&
      passwordValidation.digit &&
      passwordValidation.space &&
      passwordValidation.specialCharacter
    ) {
      setShowPasswordError(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === CHAR_CODE.ENTER_KEY_CODE) {
      handleSubmit(onSubmit)();
    }
  };

  const onBlur = () => {
    setIsPasswordNotFocused(true);
  };
  const onFocus = () => {
    setIsPasswordNotFocused(false);
  };

  const isPasswordCriteriaValid = () => {
    if (
      passwordValidation.tenCharacters &&
      passwordValidation.upperCase &&
      passwordValidation.lowerCase &&
      passwordValidation.digit &&
      passwordValidation.space &&
      passwordValidation.specialCharacter
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex md:flex-row flex-col md:gap-12 gap-6 w-full initial-wrapper">
      <div className="md:w-1/2 w-full flex flex-col justify-between">
        <LGSubtitleText title="Create your account" textWeight="text-medium" />
        <div className="flex flex-col gap-4 wrapper-left-side-container">
          <XSText title="Confirm your email address and set your password" fontWeight="text-medium" />
          <form id="email-filed" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              disabled
              value={sessionStorage.getItem('email').replace(/ /g, '+')}
              name="email"
              label="Email address"
              variant="filled"
            />
            <div className="relative flex flex-col-reverse md:flex-row md:justify-center items-center">
              <div
                className={`${
                  password && !isPasswordCriteriaValid() && !isPasswordNotFocused ? 'block mt-4' : 'hidden'
                } p-4 md:absolute  md:bg-white bg-green-200 rounded-lg ${
                  showPasswordError
                    ? 'create-password-validation-container-on-error'
                    : 'create-password-validation-container'
                }`}
              >
                <BaseText className="mb-4" fontWeight="text-bold" title={'Your password must have:'} />
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.tenCharacters ? (
                    <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                  ) : (
                    <CheckByStreamLineHq />
                  )}
                  <MSText
                    textColor={passwordValidation.tenCharacters ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.tenCharacters && 'text-bold'}
                    title={'At least 10 characters'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.upperCase ? (
                    <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                  ) : (
                    <CheckByStreamLineHq />
                  )}
                  <MSText
                    textColor={passwordValidation.upperCase ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.upperCase && 'text-bold'}
                    title={'At least one uppercase letter'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.lowerCase ? (
                    <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                  ) : (
                    <CheckByStreamLineHq />
                  )}
                  <MSText
                    textColor={passwordValidation.lowerCase ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.lowerCase && 'text-bold'}
                    title={'At least one lowercase letter'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.digit ? <CheckByStreamLineHq fill={MUI_COLORS.GREEN} /> : <CheckByStreamLineHq />}
                  <MSText
                    textColor={passwordValidation.digit ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.digit && 'text-bold'}
                    title={'At least one digit number'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.space ? <CheckByStreamLineHq fill={MUI_COLORS.GREEN} /> : <CheckByStreamLineHq />}
                  <MSText
                    textColor={passwordValidation.space ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.space && 'text-bold'}
                    title={'No spaces'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.specialCharacter ? (
                    <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                  ) : (
                    <CheckByStreamLineHq />
                  )}
                  <MSText
                    textColor={passwordValidation.specialCharacter ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.specialCharacter && 'text-bold'}
                    title={'At least one special character ( # * ! etc. )'}
                  />
                </div>
                <div className="absolute w-full left-0 right-0 mx-auto mt-5 validation-div-triangle-down"></div>
              </div>
              <div className="w-full">
                <Controller
                  control={control}
                  name="password"
                  render={({ value }) => (
                    <TextInput
                      type={showPassword?.currentPassword ? 'text' : 'password'}
                      label="Password"
                      name="password"
                      onRightSideAdornment={currentPasswordClicked}
                      value={value}
                      onChange={handleChange}
                      endAdornment={showPassword?.currentPassword ? <Visibility /> : <VisibilityOff />}
                      textFieldContainerClass="mt-4"
                      inputRef={register({
                        required: true
                      })}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      error={errors?.password || showPasswordError ? true : false}
                      helperText={
                        (errors?.password || showPasswordError) && (
                          <ValidationMessage title={'Please fill in a valid password'} />
                        )
                      }
                    />
                  )}
                />
              </div>
            </div>
            <Controller
              control={control}
              name="confirmPassword"
              render={({ value }) => (
                <TextInput
                  type={showPassword?.confirmPassword ? 'text' : 'password'}
                  label="Confirm Password"
                  name="confirmPassword"
                  onRightSideAdornment={confirmPasswordClicked}
                  endAdornment={showPassword?.confirmPassword ? <Visibility /> : <VisibilityOff />}
                  textFieldContainerClass="mt-4"
                  value={value}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  inputRef={register({
                    required: true
                  })}
                  error={errors?.confirmPassword || showConfirmPasswordError ? true : false}
                  helperText={
                    (errors?.confirmPassword || showConfirmPasswordError) && (
                      <ValidationMessage title={'Both passwords must be same'} />
                    )
                  }
                />
              )}
            />
          </form>
        </div>
        <div className="flex flex-row md:mt-0 mt-4 justify-between wrapper-left-side-container">
          <BackButton onClick={() => prevStep()} />
          <PrimaryButton
            caption="Continue"
            color="text-white"
            isLoading={loading}
            isBorderRequired={true}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
      <div className="md:w-1/2 w-full flex justify-center">
        <img src={PasswordMan} alt="mail for you" width="368" className="object-contain" />
      </div>
    </div>
  );
};

export default Email;
