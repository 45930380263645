import React, { useRef, useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { concatenateSelectedCurrencyForPDF, isIbanNumber } from '../../helper/helpers';
import { MUI_COLORS, INVOICE_FONTS, EXTERNAL_PAYMENT_LINKS, INVOICE_STATUS } from '../../constants/enum';
import DownloadIcon from '../../images/icons/download-icon.svg';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { convertDateToDDMMYYYY } from '../../helper/helpers';
import QRCodeGenerator from '../common/QRCodeGenerator';
import PropTypes from 'prop-types';

Font.register({
  family: 'HKGrotesk-Regular',
  src: INVOICE_FONTS?.HKGROTESK_REGULAR
});

Font.register({
  family: 'HKGrotesk-Bold',
  src: INVOICE_FONTS?.HKGROTESK_BOLD
});

Font.register({
  family: 'Microsoft',
  src: INVOICE_FONTS?.MICROSOFT
});
Font.register({
  family: 'Noto-Sans',
  src: INVOICE_FONTS?.GOOGLE_NOTO
});

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: '1.4cm',
    paddingVertical: '1.2cm',
    fontSize: '0.37cm',
    fontFamily: ['HKGrotesk-Regular', 'Microsoft', 'Noto-Sans'],
    color: MUI_COLORS.MID_NIGHT,
    height: '100%',
    letterSpacing: '-0.01cm'
  },
  invoiceImage: {
    maxWidth: '100%',
    maxHeight: '2cm',
    flexDirection: 'row',
    justifyContent: 'start'
  },
  paidImage: {
    maxWidth: '3.6cm',
    maxHeight: '2cm',
    flexDirection: 'row',
    justifyContent: 'start',
    objectFit: 'contain'
  },
  invoicecImage: {
    objectFit: 'contain',
    objectPosition: 'left'
  },
  fromAndToContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'between',
    paddingVertical: '0.63cm'
  },
  section: {
    flexGrow: 1,
    fontSize: '0.37cm',
    flex: 1
  },
  fromAndTo: {
    flexDirection: 'row',
    textTransform: 'capitalize'
  },
  invoiceBilledTo: {
    color: MUI_COLORS.DARK_GRAYISH_BLUE,
    fontSize: '0.42cm'
  },
  title: {
    fontSize: '0.84cm',
    fontFamily: ['HKGrotesk-Bold', 'Microsoft', 'Noto-Sans'], //bold
    textTransform: 'capitalize',
    letterSpacing: '-0.02cm'
  },
  invoiceTitle: {},
  flexContainer: {
    flexDirection: 'row'
  },
  invoiceTitleAndDetails: {
    width: '40%',
    alignItems: 'flex-end',
    fontSize: '0.37cm'
  },
  invoiceTitleContainer: {
    marginTop: '0.37cm',
    flexGrow: 1
  },
  invoiceDetails: {
    color: MUI_COLORS.DARK_GRAYISH_BLUE,
    marginTop: '0.21cm',
    flexDirection: 'row'
  },
  invoiceTotalAmount: {
    fontFamily: ['HKGrotesk-Bold', 'Microsoft', 'Noto-Sans'], //bold
    letterSpacing: '-0.02cm'
  },
  baseTextColor: {
    color: MUI_COLORS.MID_NIGHT
  },
  invoiceDetailsTitle: {
    fontFamily: ['HKGrotesk-Bold', 'Microsoft', 'Noto-Sans'], //bold
    fontWeight: '700'
  },
  invoiceDescriptionHeader: {
    fontFamily: ['HKGrotesk-Bold', 'Microsoft', 'Noto-Sans'], //bold
    color: MUI_COLORS.DARK_GRAYISH_BLUE,
    fontSize: '0.47cm',
    borderBottom: '0.02cm',
    borderBottomColor: MUI_COLORS.DARK_GRAYISH_BLUE,
    paddingBottom: '0.21cm'
  },
  itemAndTaskContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: '0.63cm',
    borderBottom: '0.02cm',
    borderBottomColor: MUI_COLORS.DARK_GRAYISH_BLUE
  },
  taskSection: {
    flexGrow: 0.4,
    flex: 0.4
  },
  itemsSection: {
    flexGrow: 0.6,
    flex: 0.6
  },
  descriptionSection: {
    flexGrow: 0.6,
    flex: 0.6,
    marginHorizontal: '0.69cm'
  },
  priceSection: {
    flexGrow: 1,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'between'
  },
  taskContainer: {
    marginTop: '0.63cm',
    flexDirection: 'row',
    width: '100%',
    paddingVertical: '0.63cm',
    borderBottom: '0.02cm',
    borderBottomColor: MUI_COLORS.DARK_GRAYISH_BLUE,
    color: MUI_COLORS.DARK_GRAYISH_BLUE
  },
  summaryContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'between',
    marginTop: '0.63cm'
  },
  textLight: {
    color: MUI_COLORS.DARK_GRAYISH_BLUE
  },
  invoiceItem: {
    marginHorizontal: '0.37cm',
    textAlign: 'center',
    flexGrow: 1,
    flex: 1
  },
  paymentDetailsContainer: {
    backgroundColor: MUI_COLORS.EXTRA_LIGHT_GRAY,
    padding: '0.42cm',
    borderRadius: '0.21cm',
    width: '100%'
  },
  bankDetailsColumn: {
    marginBottom: '0.21cm',
    minWidth: '3.81cm'
  },
  QRImage: {
    width: '1.4cm',
    height: '1.4cm',
    marginRight: '0.31cm'
  },
  wordBreak: {
    flex: 1
  },
  invoiceTitleAndPaid: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  paidContainer: {
    backgroundColor: MUI_COLORS.GREEN,
    borderRadius: '0.191cm',
    marginRight: '0.2cm'
  },
  paidText: {
    padding: '0.211cm',
    color: MUI_COLORS.WHITE,
    fontFamily: ['HKGrotesk-Bold', 'Microsoft', 'Noto-Sans'], //bold
    letterSpacing: '0.0132cm'
  }
});
export const InvoiceAsPDF = ({ getInvoiceDetails }) => (
  <Document>
    <Page wrap={false} style={styles.page}>
      <View style={styles.fromAndToContainer}>
        <View
          style={[
            styles.section,
            {
              marginRight: '1.05cm'
            }
          ]}
        >
          <View style={styles.invoiceImage}>
            {getInvoiceDetails?.logoPublic && (
              <Image
                style={styles.invoicecImage}
                src={getInvoiceDetails?.logoPublic + '?noCache=' + Math.random().toString()}
              />
            )}
          </View>
          <View style={[{ marginTop: '0.62cm' }]}>
            <Text
              style={[
                !getInvoiceDetails?.companyDetails?.companyName && styles.invoiceDetailsTitle,
                { textTransform: 'capitalize' }
              ]}
            >
              {getInvoiceDetails?.companyDetails?.firstName} {getInvoiceDetails?.companyDetails?.lastName}
            </Text>
            <Text style={[styles.invoiceDetailsTitle, { textTransform: 'capitalize' }]}>
              {getInvoiceDetails?.companyDetails?.companyName && (
                <Text>{getInvoiceDetails?.companyDetails?.companyName}</Text>
              )}
            </Text>
            {getInvoiceDetails?.companyDetails?.registrationNumber && (
              <Text># {getInvoiceDetails?.companyDetails?.registrationNumber}</Text>
            )}
            {(getInvoiceDetails?.companyDetails?.address ||
              getInvoiceDetails?.companyDetails?.townCity ||
              getInvoiceDetails?.companyDetails?.state ||
              getInvoiceDetails?.companyDetails?.postalCode) && (
              <View style={styles.fromAndTo}>
                <Text>
                  {getInvoiceDetails?.companyDetails?.address} {getInvoiceDetails?.companyDetails?.townCity}{' '}
                  {getInvoiceDetails?.companyDetails?.state} {getInvoiceDetails?.companyDetails?.postalCode}
                </Text>
              </View>
            )}
            {getInvoiceDetails?.companyDetails?.country && (
              <Text style={{ textTransform: 'uppercase' }}>{getInvoiceDetails?.companyDetails?.country}</Text>
            )}
            {getInvoiceDetails?.companyDetails?.website && <Text>{getInvoiceDetails?.companyDetails?.website}</Text>}
            {getInvoiceDetails?.companyDetails?.email && <Text>{getInvoiceDetails?.companyDetails?.email}</Text>}
          </View>
          <View style={[{ marginTop: '0.62cm' }]}>
            <Text style={styles.invoiceBilledTo}>Billed to</Text>
          </View>
          <View style={[{ marginTop: '0.21cm' }]}>
            <Text style={[styles.invoiceDetailsTitle, { textTransform: 'capitalize' }]}>
              {getInvoiceDetails?.contact?.companyName && getInvoiceDetails?.contact?.companyName}
            </Text>
            {getInvoiceDetails?.contact?.registrationNumber && (
              <Text># {getInvoiceDetails?.contact?.registrationNumber}</Text>
            )}

            {(getInvoiceDetails?.contact?.address ||
              getInvoiceDetails?.contact?.townCity ||
              getInvoiceDetails?.contact?.state ||
              getInvoiceDetails?.contact?.postalCode) && (
              <View style={styles.fromAndTo}>
                <Text>
                  {getInvoiceDetails?.contact?.address} {getInvoiceDetails?.contact?.townCity}{' '}
                  {getInvoiceDetails?.contact?.state} {getInvoiceDetails?.contact?.postalCode}
                </Text>
              </View>
            )}
            {getInvoiceDetails?.contact?.country && (
              <Text style={{ textTransform: 'uppercase' }}>{getInvoiceDetails?.contact?.country}</Text>
            )}
            {getInvoiceDetails?.contact?.website && <Text>{getInvoiceDetails?.contact?.website}</Text>}
            <Text
              style={[
                !getInvoiceDetails?.contact?.companyName && styles.invoiceDetailsTitle,
                { textTransform: 'capitalize', marginTop: '0.62cm' }
              ]}
            >
              {getInvoiceDetails?.contact?.firstName} {getInvoiceDetails?.contact?.lastName}
            </Text>
            {getInvoiceDetails?.contact?.email && <Text>{getInvoiceDetails?.contact?.email}</Text>}
          </View>
        </View>

        <View style={styles.invoiceTitleAndDetails}>
          <View style={styles.invoiceTitleAndPaid}>
            {getInvoiceDetails?.status === INVOICE_STATUS.INVOICE_PAID && (
              <View style={styles.paidContainer}>
                <Text style={styles.paidText}>Paid</Text>
              </View>
            )}
            <Text style={styles.title}>Invoice</Text>
          </View>
          <View style={styles.invoiceDetails}>
            <Text>Invoice Number: </Text> <Text style={styles.baseTextColor}> {getInvoiceDetails?.invoiceNumber}</Text>
          </View>
          <View style={[styles.invoiceDetails, { marginTop: '0.42cm' }]}>
            <Text>Date: </Text>{' '}
            <Text style={styles.baseTextColor}> {convertDateToDDMMYYYY(getInvoiceDetails?.createDate)}</Text>
          </View>
          {getInvoiceDetails?.payments?.[0]?.paidDate ? (
            <View style={[styles.invoiceDetails, { marginTop: '0.42cm' }]}>
              <Text>Paid at: </Text>{' '}
              <Text style={styles.baseTextColor}>
                {' '}
                {convertDateToDDMMYYYY(getInvoiceDetails?.payments?.[0]?.paidDate)}
              </Text>
            </View>
          ) : (
            <View style={[styles.invoiceDetails, { marginTop: '0.42cm' }]}>
              <Text>Due: </Text>{' '}
              <Text style={styles.baseTextColor}> {convertDateToDDMMYYYY(getInvoiceDetails?.dueDate)}</Text>
            </View>
          )}
          <View style={[styles.invoiceDetails, { marginTop: '0.42cm' }]}>
            <Text>Invoice amount: </Text>{' '}
            <Text style={styles.baseTextColor}>
              {concatenateSelectedCurrencyForPDF(getInvoiceDetails?.currency, getInvoiceDetails?.totalAmount)}
            </Text>
          </View>
          <View style={[styles.invoiceDetails, { marginTop: '0.42cm' }]}>
            <Text>
              {getInvoiceDetails?.otherReferences && (
                <Text style={styles.baseTextColor}>{getInvoiceDetails?.otherReferences}</Text>
              )}
            </Text>
          </View>
        </View>
      </View>

      <View>
        <Text style={styles.invoiceDescriptionHeader}>Description</Text>
      </View>
      {getInvoiceDetails?.services?.length > 0 && (
        <View style={[styles.itemAndTaskContainer, { color: MUI_COLORS.DARK_GRAYISH_BLUE }]}>
          <View style={styles.taskSection}>
            <Text>Items</Text>
          </View>
          <View style={styles.descriptionSection}>
            <Text>Description</Text>
          </View>
          <View style={styles.priceSection}>
            <View style={styles.invoiceItem}>
              <Text style={{ textAlign: 'left' }}>Rate</Text>
            </View>
            <View>
              <Text>Hours</Text>
            </View>
            <View style={{ flexGrow: 1, flex: 1, textAlign: 'right' }}>
              <Text style={{ textAlign: 'right' }}>Total</Text>
            </View>
          </View>
        </View>
      )}
      {getInvoiceDetails?.services?.map((taskDetails, index) => {
        return (
          <View key={index} style={styles.itemAndTaskContainer}>
            <View style={styles.taskSection}>
              <Text>{taskDetails?.taskTitle}</Text>
            </View>
            <View style={styles.descriptionSection}>
              <Text>{taskDetails?.taskDescription}</Text>
            </View>
            <View style={styles.priceSection}>
              <View style={styles.invoiceItem}>
                <Text style={{ textAlign: 'left' }}>
                  {concatenateSelectedCurrencyForPDF(getInvoiceDetails?.currency, taskDetails?.taskRate)}
                </Text>
              </View>
              <View>
                <Text>{taskDetails?.taskHours}</Text>
              </View>
              <View style={{ flexGrow: 1, flex: 1, textAlign: 'right' }}>
                <Text style={{ textAlign: 'right' }}>
                  {concatenateSelectedCurrencyForPDF(
                    getInvoiceDetails?.currency,
                    taskDetails?.taskRate * taskDetails?.taskHours
                  )}
                </Text>
              </View>
            </View>
          </View>
        );
      })}
      {getInvoiceDetails?.goods?.length > 0 && (
        <View style={[styles.taskContainer, { marginTop: '0.63cm', color: MUI_COLORS.DARK_GRAYISH_BLUE }]}>
          <View style={styles.taskSection}>
            <Text>Items</Text>
          </View>
          <View style={styles.descriptionSection}>
            <Text>Description</Text>
          </View>
          <View style={styles.priceSection}>
            <View>
              <Text>Qty</Text>
            </View>
            <View style={styles.invoiceItem}>
              <Text style={{ textAlign: 'center' }}>Price</Text>
            </View>
            <View style={{ flexGrow: 1, flex: 1, textAlign: 'right' }}>
              <Text style={{ textAlign: 'right' }}>Total</Text>
            </View>
          </View>
        </View>
      )}

      {getInvoiceDetails?.goods?.map((itemDetails, index) => {
        return (
          <View key={index} style={styles.itemAndTaskContainer}>
            <View style={styles.taskSection}>
              <Text>{itemDetails?.itemTitle}</Text>
            </View>
            <View style={styles.descriptionSection}>
              <Text>{itemDetails?.itemDescription}</Text>
            </View>
            <View style={styles.priceSection}>
              <View>
                <Text>{itemDetails?.itemQuantity}</Text>
              </View>
              <View style={styles.invoiceItem}>
                <Text style={{ textAlign: 'center' }}>
                  {concatenateSelectedCurrencyForPDF(getInvoiceDetails?.currency, itemDetails?.itemPrice)}
                </Text>
              </View>
              <View style={{ flexGrow: 1, flex: 1, textAlign: 'right' }}>
                <Text style={{ textAlign: 'right' }}>
                  {concatenateSelectedCurrencyForPDF(
                    getInvoiceDetails?.currency,
                    itemDetails?.itemQuantity * itemDetails?.itemPrice
                  )}
                </Text>
              </View>
            </View>
          </View>
        );
      })}

      {/* NEW INVOICE ITEMS */}

      {getInvoiceDetails?.items?.length > 0 && (
        <View style={[styles.itemAndTaskContainer, { color: MUI_COLORS.DARK_GRAYISH_BLUE }]}>
          <View style={styles.itemsSection}>
            <Text>Items</Text>
          </View>
          <View style={styles.priceSection}>
            <View style={{ flexGrow: 0.7, flex: 0.7 }}>
              <Text>Quantity</Text>
            </View>
            <View style={{ flexGrow: 0.7, flex: 0.7 }}>
              <Text style={{ textAlign: 'left' }}>Unit</Text>
            </View>
            <View style={styles.invoiceItem}>
              <Text style={{ textAlign: 'left' }}>Price</Text>
            </View>
            <View style={{ flexGrow: 1, flex: 1, textAlign: 'right' }}>
              <Text style={{ textAlign: 'right' }}>Total</Text>
            </View>
          </View>
        </View>
      )}
      {getInvoiceDetails?.items?.map((itemDetails, index) => {
        return (
          <View key={index} style={styles.itemAndTaskContainer}>
            <View style={styles.itemsSection}>
              <Text>{itemDetails?.itemTitle}</Text>
              <Text>{itemDetails?.itemDescription}</Text>
            </View>

            <View style={styles.priceSection}>
              <View style={{ flexGrow: 0.7, flex: 0.7 }}>
                <Text>{itemDetails?.itemQuantity}</Text>
              </View>
              <View style={{ flexGrow: 0.7, flex: 0.7 }}>
                <Text style={{ textAlign: 'left' }}>{itemDetails?.unit.name}</Text>
              </View>
              <View style={styles.invoiceItem}>
                <Text style={{ textAlign: 'left' }}>
                  {concatenateSelectedCurrencyForPDF(getInvoiceDetails?.currency, itemDetails?.itemPriceRate)}
                </Text>
              </View>
              <View style={{ flexGrow: 1, flex: 1, textAlign: 'right' }}>
                <Text style={{ textAlign: 'right' }}>
                  {concatenateSelectedCurrencyForPDF(getInvoiceDetails?.currency, itemDetails?.itemTotal)}
                </Text>
              </View>
            </View>
          </View>
        );
      })}
      <View style={styles.summaryContainer}>
        <View style={[styles.section, { minWidth: '60%', maxWidth: '60%', marginRight: '0.84cm' }]}>
          {getInvoiceDetails?.paymentDetails && (
            <Text style={[styles.textLight, { marginBottom: '0.26cm' }]}>Payment Details</Text>
          )}
          {getInvoiceDetails?.paymentDetails?.bankDetails?.map((bankData, index) => (
            <View style={[styles.paymentDetailsContainer, { marginTop: '0.21cm' }]} key={index + 1}>
              <View style={{ flex: 1, flexGrow: 1 }}>
                {bankData?.bankCountry && (
                  <View style={{ flexDirection: 'row', marginBottom: '0.10cm' }}>
                    <Text style={styles.bankDetailsColumn}>Bank country:</Text>
                    <Text>{bankData?.bankCountry}</Text>
                  </View>
                )}
                {bankData?.bankAddress && (
                  <View style={{ flexDirection: 'row', marginBottom: '0.10cm' }}>
                    <Text style={styles.bankDetailsColumn}>Bank address:</Text>
                    <Text style={styles.wordBreak}>{bankData?.bankAddress}</Text>
                  </View>
                )}
                {bankData?.fullName && (
                  <View style={{ flexDirection: 'row', marginBottom: '0.10cm' }}>
                    <Text style={styles.bankDetailsColumn}>Full Name:</Text>
                    <Text style={styles.wordBreak}>{bankData?.fullName}</Text>
                  </View>
                )}
                {bankData?.bankName && (
                  <View style={{ flexDirection: 'row', marginBottom: '0.10cm' }}>
                    <Text style={styles.bankDetailsColumn}>Bank name:</Text>
                    <Text style={styles.wordBreak}>{bankData?.bankName}</Text>
                  </View>
                )}
                {bankData?.accountNumberOrIban && (
                  <View style={{ flexDirection: 'row', marginBottom: '0.10cm' }}>
                    <Text style={styles.bankDetailsColumn}>{isIbanNumber(bankData?.accountNumberOrIban)}</Text>
                    <Text style={styles.wordBreak}>{bankData?.accountNumberOrIban}</Text>
                  </View>
                )}
                {bankData?.accountNumberOrIban && (
                  <View style={{ flexDirection: 'row', marginBottom: '0.10cm' }}>
                    <Text style={styles.bankDetailsColumn}>SWIFT/BIC code:</Text>
                    <Text style={styles.wordBreak}>{bankData?.swiftCode}</Text>
                  </View>
                )}
                {bankData?.routingCode && (
                  <View style={{ flexDirection: 'row', marginBottom: '0.10cm' }}>
                    <Text style={styles.bankDetailsColumn}>Routing Code:</Text>
                    <Text style={styles.wordBreak}>{bankData?.routingCode}</Text>
                  </View>
                )}
              </View>
              <View>
                <View>
                  {bankData?.bankCode && (
                    <View style={{ flexDirection: 'row', marginRight: '0.84cm', minWidth: '4cm' }}>
                      <Text style={styles.bankDetailsColumn}>Bank code:</Text>
                      <Text style={styles.wordBreak}>{bankData?.bankCode}</Text>
                    </View>
                  )}
                  {bankData?.branchCode && (
                    <View style={{ flexDirection: 'row', marginRight: '0.84cm', minWidth: '4cm' }}>
                      <Text style={styles.bankDetailsColumn}>Branch code:</Text>
                      <Text>{bankData?.branchCode}</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          ))}
          {getInvoiceDetails?.paymentDetails?.paypalDetails?.isSelected && (
            <View style={styles.paymentDetailsContainer}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {getInvoiceDetails?.paymentDetails?.paypalDetails?.showQrCode && (
                  <Image
                    allowDangerousPaths
                    src={document.getElementById('paypal#QR')?.toDataURL()}
                    style={styles.QRImage}
                  />
                )}
                <View>
                  <Text>Pay with PayPal</Text>
                  <Text style={[styles.invoiceDetailsTitle, { color: MUI_COLORS.CORAL, flex: 1 }]}>
                    {EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}
                    {getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink}
                    {getInvoiceDetails?.totalAmount
                      ? `/${getInvoiceDetails?.totalAmount}${getInvoiceDetails?.currency?.name}`
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
          )}
          {getInvoiceDetails?.paymentDetails?.hsbcDetails?.isSelected && (
            <View style={[styles.paymentDetailsContainer, { marginTop: '0.21cm' }]}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {getInvoiceDetails?.paymentDetails?.hsbcDetails?.showQrCode && (
                  <Image
                    allowDangerousPaths
                    src={document.getElementById('hsbc#QR')?.toDataURL()}
                    style={styles.QRImage}
                  />
                )}
                <View>
                  <Text>Pay with PayMe</Text>
                  <Text style={[styles.invoiceDetailsTitle, { color: MUI_COLORS.CORAL, flex: 1 }]}>
                    {EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}
                    {getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink}
                  </Text>
                </View>
              </View>
            </View>
          )}
          {getInvoiceDetails?.paymentDetails?.customPaymentDetails?.isSelected && (
            <View style={[styles.paymentDetailsContainer, { marginTop: '0.21cm' }]}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View>
                  <Text>{getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLabel}</Text>
                  <Text style={[styles.invoiceDetailsTitle, { color: MUI_COLORS.CORAL, flex: 1 }]}>
                    {getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLink}
                  </Text>
                </View>
              </View>
            </View>
          )}
          {getInvoiceDetails?.paymentDetails?.customTextDetails?.isSelected && (
            <View style={[styles.paymentDetailsContainer, { marginTop: '0.21cm' }]}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View>
                  <Text>{getInvoiceDetails?.paymentDetails?.customTextDetails?.customText}</Text>
                </View>
              </View>
            </View>
          )}
          {getInvoiceDetails?.notes && (
            <Text style={[styles.textLight, { marginTop: '0.68cm', marginBottom: '0.15cm' }]}>Notes</Text>
          )}
          <Text style={styles.wordBreak}>{getInvoiceDetails?.notes}</Text>
        </View>
        <View style={[styles.section, { width: '35%', marginTop: '0.63cm' }]}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '0.63cm'
            }}
          >
            <View style={{ width: '2.11cm' }}>
              <Text>Subtotal</Text>
            </View>
            <Text>{concatenateSelectedCurrencyForPDF(getInvoiceDetails?.currency, getInvoiceDetails?.subtotal)}</Text>
          </View>
          {getInvoiceDetails?.invoiceDiscount?.discountPercentage &&
            getInvoiceDetails?.invoiceDiscount?.discountPercentage !== '0' && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '0.63cm'
                }}
              >
                <Text>{getInvoiceDetails?.invoiceDiscount?.discountName}</Text>
                <Text>{getInvoiceDetails?.invoiceDiscount?.discountPercentage}%</Text>
                <Text>
                  -
                  {concatenateSelectedCurrencyForPDF(
                    getInvoiceDetails?.currency,
                    getInvoiceDetails?.invoiceDiscount?.discountPrice
                  )}
                </Text>
              </View>
            )}

          {getInvoiceDetails?.taxWithPrice?.map((taxDetails, index) => {
            return (
              <View
                key={index}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '0.63cm'
                }}
              >
                <Text>{taxDetails?.taxConcept}</Text>
                <Text>{taxDetails?.taxPercentage}%</Text>

                <Text>
                  {' '}
                  {Number(taxDetails?.taxPrice) < 0
                    ? `-${concatenateSelectedCurrencyForPDF(
                        getInvoiceDetails?.currency,
                        Math.abs(taxDetails?.taxPrice)
                      )}`
                    : `${concatenateSelectedCurrencyForPDF(getInvoiceDetails?.currency, taxDetails?.taxPrice)}`}
                </Text>
              </View>
            );
          })}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              fontFamily: 'HKGrotesk-Regular', //bold
              fontSize: '0.42cm'
            }}
          >
            <Text style={styles.invoiceTotalAmount}>Total {`(${getInvoiceDetails?.currency?.name})`}</Text>
            <Text style={styles.invoiceTotalAmount}>
              {concatenateSelectedCurrencyForPDF(getInvoiceDetails?.currency, getInvoiceDetails?.totalAmount)}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

function DownloadInvoiceAsPDF({
  caption,
  getInvoiceDetails,
  isIcon,
  bgColor,
  fontSize,
  id,
  color,
  className,
  type,
  insideClass
}) {
  const [isQrGenerated, setIsQrGenerated] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  const containerRef = useRef();
  const downloadPDF = async () => {
    await containerRef.current.children[0].click();
    setIsLoading(false);
  };
  const handleDownloadInvoice = async () => {
    setIsLoading(true);
    downloadPDF();
  };

  const generateQRCode = () => {
    setIsQrGenerated(true);
  };

  return (
    <>
      <PrimaryButton
        type={type}
        isLoading={isLoading}
        className={className}
        id="download-invoice-btn"
        bgColor={bgColor}
        color={color}
        fontSize={fontSize}
        caption={
          <div className={`flex justify-center gap-2 items-center ${insideClass}`}>
            {isIcon && <img src={DownloadIcon} alt="downloadIcon" />}
            {caption}
          </div>
        }
        onClick={handleDownloadInvoice}
      />
      <div className="hidden" ref={containerRef}>
        {isClient && (
          <PDFDownloadLink
            document={<InvoiceAsPDF getInvoiceDetails={getInvoiceDetails} />}
            fileName={getInvoiceDetails?.invoiceNumber}
          >
            {({ loading }) => (loading ? 'Loading document...' : 'Download now!')}
          </PDFDownloadLink>
        )}

        {isQrGenerated ? (
          <>
            <QRCodeGenerator
              valueString={`${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${
                getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink
              }${
                getInvoiceDetails?.totalAmount
                  ? `/${getInvoiceDetails?.totalAmount}${getInvoiceDetails?.currency?.name}`
                  : ''
              }`}
              documentId="paypal#QR"
            />
            <QRCodeGenerator
              valueString={`${EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}${getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink}`}
              documentId="hsbc#QR"
            />
          </>
        ) : (
          <>
            <QRCodeGenerator
              valueString={`${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${
                getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink
              }${
                getInvoiceDetails?.totalAmount
                  ? `/${getInvoiceDetails?.totalAmount}${getInvoiceDetails?.currency?.name}`
                  : ''
              }`}
              documentId="paypal#QR"
              onLoad={generateQRCode}
            />
            <QRCodeGenerator
              valueString={`${EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}${getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink}`}
              documentId="hsbc#QR"
              onLoad={generateQRCode}
            />
          </>
        )}
      </div>
    </>
  );
}

export default DownloadInvoiceAsPDF;

DownloadInvoiceAsPDF.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  fontSize: PropTypes.string,
  type: PropTypes.string,
  isIcon: PropTypes.bool,
  insideClass: PropTypes.string
};

DownloadInvoiceAsPDF.defaultProps = {
  isIcon: true,
  bgColor: 'bg-white',
  color: 'text-coral-500',
  fontSize: 'text-lg',
  className: 'get-paid-invoice-download-button w-full',
  type: 'small',
  insideClass: 'text-medium'
};
