import React, { useEffect, useState } from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from './reduxStore/action';
import Plus from '../../images/icons/customSvg/plus';
import IconBaseEdit from '../../images/icons/Invoice/base-edit.svg';
import FromAndToform from './FromAndToForm';
import MSText from '../ui/typography/MSText';
import ValidationMessage from '../ui/ValidationMessage';
import * as accountSettingActions from '../accountSetting/reduxStore/action';
import { API_CODE, FILE_UPLOAD_TYPE, MUI_COLORS, RESPONSIVENESS_BREAKPOINT_VALUE } from '../../constants/enum';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import Snackbar from '../Snackbar';
import SuccessIcon from '../../images/icons/success-icon.svg';
import XSText from '../ui/typography/XSText';
import Image from '../Image';
import useWindowDimensions from '../../hooks/useWindowDimensions';
function InvoiceFrom({ getInvoiceDetails, getCompanyDetails, invoiceData, isTemporary }) {
  const [reviewErrorModal, setReviewErrorModal] = useState(false);
  const [companyDetails, setCompanyDetails] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });
  const { width } = useWindowDimensions();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (companydetailResponse) => {
    const companyDetails = companydetailResponse?.data;
    setOpen(true);
    setSuccessMessage({
      ...successMessage,
      title: companydetailResponse?.message,
      content: `Invoice ${
        companyDetails?.companyName ? companyDetails?.companyName : companyDetails?.firstName
      } wassucessfully saved!`
    });
    closeDynamicModal();
  };

  const closeDynamicModal = () => {
    setReviewErrorModal(false);
    if (!isTemporary) {
      accountSettingDetails();
    }
  };

  useEffect(() => {
    if (!isTemporary) {
      accountSettingDetails();
    }
  }, []);
  const accountSettingDetails = async () => {
    setLoading(true);
    const companyDetailsResponse = await getCompanyDetails();
    if (companyDetailsResponse?.status === API_CODE.STATUS_200) {
      setLoading(false);
      setCompanyDetails(companyDetailsResponse?.data?.data);
      const companyDetails = companyDetailsResponse?.data?.data;
      invoiceData({
        fromFirstName: companyDetails?.name,
        fromLastName: companyDetails?.lastName,
        fromEmail: companyDetails?.email,
        fromCompanyName: companyDetails?.companyName,
        fromRegistrationNumber: companyDetails?.registrationNumber,
        fromWebsite: companyDetails?.website,
        fromAddress: companyDetails?.address,
        fromTownCity: companyDetails?.city,
        fromState: companyDetails?.state,
        fromPostalCode: companyDetails?.postalCode,
        fromCountry: companyDetails?.country,
        invoiceImage: companyDetails?.logoPublic
          ? { preview: companyDetails?.logoPublic, type: FILE_UPLOAD_TYPE.URL }
          : null
      });
    } else {
      setLoading(false);
      setError(true);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-56">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <div className="w-full">
            <div
              className={`w-full ${
                getInvoiceDetails?.fromFirstName || getInvoiceDetails?.fromCompanyName
                  ? 'invoice-from-filled-container bg-white flex md:flex-row flex-col'
                  : 'invoice-from-empty-container bg-yellow-300 flex items-start'
              } rounded-2xl cursor-pointer justify-between`}
              onClick={() => setReviewErrorModal(true)}
            >
              <div className="flex flex-col justify-start w-11/12">
                {!getInvoiceDetails?.fromFirstName && !getInvoiceDetails?.fromCompanyName && (
                  <MSText textColor="text-gray-600" title="Your company details" />
                )}
                {(getInvoiceDetails?.fromFirstName || getInvoiceDetails?.fromCompanyName) && (
                  <MSText
                    className="capitalize mt-2"
                    title={
                      <div className="flex flex-col gap-1">
                        {getInvoiceDetails?.invoiceImage?.preview && (
                          <img
                            className="invoice-preview-image mb-8"
                            src={getInvoiceDetails?.invoiceImage?.preview}
                            alt="document"
                          />
                        )}

                        <div className="flex gap-1">
                          <div>
                            {getInvoiceDetails?.fromFirstName} {getInvoiceDetails?.fromLastName}
                          </div>
                        </div>
                        {getInvoiceDetails?.fromCompanyName && (
                          <div
                            className={
                              getInvoiceDetails?.invoiceImage?.preview
                                ? `invoice-from-to-name`
                                : `invoice-from-to-name-bold`
                            }
                          >
                            {getInvoiceDetails?.fromCompanyName}
                          </div>
                        )}
                        {getInvoiceDetails?.fromRegistrationNumber && (
                          <div className="normal-case">{getInvoiceDetails?.fromRegistrationNumber}</div>
                        )}
                        {(getInvoiceDetails?.fromAddress ||
                          getInvoiceDetails?.fromTownCity ||
                          getInvoiceDetails?.fromState ||
                          getInvoiceDetails?.fromPostalCode) && (
                          <div className="flex gap-1">
                            <div>
                              {getInvoiceDetails?.fromAddress} {getInvoiceDetails?.fromTownCity}{' '}
                              {getInvoiceDetails?.fromState} {getInvoiceDetails?.fromPostalCode}
                            </div>
                          </div>
                        )}
                        {getInvoiceDetails?.fromCountry && (
                          <div className="uppercase">{getInvoiceDetails?.fromCountry}</div>
                        )}
                        {getInvoiceDetails?.fromWebsite && (
                          <div className="normal-case">{getInvoiceDetails?.fromWebsite}</div>
                        )}
                        {getInvoiceDetails?.fromEmail && (
                          <div className="normal-case">{getInvoiceDetails?.fromEmail}</div>
                        )}
                      </div>
                    }
                  />
                )}
              </div>
              <div className="md:w-3/12 w-full md:flex justify-end md:mt-0 mt-4">
                <PrimaryButton
                  id="invoice-form-add-edit-btn"
                  className="invoice-button md:w-auto w-full"
                  bgColor="bg-coral-500"
                  textColor="text-white"
                  caption={
                    getInvoiceDetails?.fromFirstName || getInvoiceDetails?.fromCompanyName ? (
                      <div className="flex gap-1 text-medium items-center justify-center">
                        <img src={IconBaseEdit} alt="IconBaseEdit" />
                        Change
                      </div>
                    ) : (
                      <div className="flex gap-2 text-medium items-center">
                        <Plus />
                        Add
                      </div>
                    )
                  }
                />
              </div>
            </div>
            <div className="ml-4">
              {getInvoiceDetails?.fromError && !getInvoiceDetails?.fromCompanyName && (
                <ValidationMessage title={'Please provide your valid Details'} />
              )}
            </div>
            <DynamicModal
              openDynamicModal={reviewErrorModal}
              closeDynamicModal={closeDynamicModal}
              maxWidth="960px"
              minWidth={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'sm' : ''}
            >
              <FromAndToform
                type="From"
                closeDynamicModal={closeDynamicModal}
                accountSettingDetails={accountSettingDetails}
                companyDetails={companyDetails}
                handleOpen={handleOpen}
                isTemporary={isTemporary}
              />
            </DynamicModal>
          </div>
          <Snackbar
            open={open}
            handleClose={handleClose}
            sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
            title={
              <div className="flex">
                <div className="flex items-center">
                  <Image className="w-5 h-5" src={SuccessIcon} />
                </div>
                <div className="ml-4">
                  <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage.title}></XSText>
                  <MSText textColor="text-gray-500" title={successMessage.content}></MSText>
                </div>
              </div>
            }
          ></Snackbar>
        </>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData)),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceFrom);

InvoiceFrom.propTypes = {
  prevStep: PropTypes.func,
  getInvoiceDetails: PropTypes.object,
  getCompanyDetails: PropTypes.object,
  invoiceData: PropTypes.func
};
