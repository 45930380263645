import * as React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Checkbox, TableRow, TableCell, Typography, Stack, Link, MenuItem, Divider } from '@mui/material';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import { TableMoreMenu } from '../../../components/table';
import { fDate } from '../../../utils/formatTime';
import { CONTACT_TABLE_TITLE, INVOICE_STATUS, RESPONSIVENESS_BREAKPOINT_VALUE } from '../../../constants/enum';
import { concatenateSelectedCurrency } from '../../../helper/helpers';
import { useLocation } from '@reach/router';
import { padding } from 'tailwindcss/defaultTheme';
// ----------------------------------------------------------------------

ViewContactTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func
};

export default function ViewContactTableRow({
  row,
  selected,
  onDuplicateRow,
  onViewRow,
  onDeleteRow,
  onEditRow,
  width
}) {
  const theme = useTheme();
  const location = useLocation();
  const { status, invoiceNumber, createdAt, totalAmount, currency } = row;

  const [openMenu, setOpenMenuActions] = useState(null);
  const [contactType, setContactType] = useState('');

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  React.useEffect(() => {
    setContactType(location?.state?.contactType);
  }, []);

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          padding: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '16px 4px 16px 4px' : '16px'
        }}
        onClick={onViewRow}
      >
        <Stack>
          <Typography variant="subtitle2" noWrap>
            {invoiceNumber}
          </Typography>
          <Link noWrap variant="body2" onClick={onViewRow} sx={{ color: 'text.disabled', cursor: 'pointer' }}>
            {createdAt && fDate(createdAt)}
          </Link>
        </Stack>
      </TableCell>
      <TableCell
        align={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'right' : 'left'}
        sx={{
          padding: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '16px 4px 16px 4px' : '16px',
          cursor: 'pointer'
        }}
        onClick={onViewRow}
      >
        <div>
          <Label
            variant={theme.palette.mode === 'light' ? 'transparent' : ''}
            color={
              (status === INVOICE_STATUS.INVOICE_PAID && 'success') ||
              (status === INVOICE_STATUS.INVOICE_UNPAID && 'warning') ||
              (status === INVOICE_STATUS.INVOICE_OVERDUE && 'error') ||
              (status === INVOICE_STATUS.PARTIALLY_PAID && 'warning') ||
              'noBackground'
            }
            sx={{ textTransform: 'capitalize', padding: '0px' }}
          >
            {totalAmount ? concatenateSelectedCurrency(currency, totalAmount) : '-'}
          </Label>
          <div>
            {width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
              <Label
                variant={theme.palette.mode === 'light' ? 'filled' : 'ghost'}
                color={
                  (status === INVOICE_STATUS.INVOICE_PAID && 'success') ||
                  (status === INVOICE_STATUS.INVOICE_UNPAID && 'warning') ||
                  (status === INVOICE_STATUS.INVOICE_OVERDUE && 'error') ||
                  (status === INVOICE_STATUS.PARTIALLY_PAID && 'warning') ||
                  'default'
                }
                sx={{ textTransform: 'capitalize' }}
              >
                {status}
              </Label>
            )}
          </div>
        </div>
      </TableCell>
      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell
          align="center"
          sx={{
            padding: width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? '16px 4px 16px 4px' : '16px',
            cursor: 'pointer'
          }}
          onClick={onViewRow}
        >
          <Label
            variant={theme.palette.mode === 'light' ? 'filled' : 'ghost'}
            color={
              (status === INVOICE_STATUS.INVOICE_PAID && 'success') ||
              (status === INVOICE_STATUS.INVOICE_UNPAID && 'warning') ||
              (status === INVOICE_STATUS.INVOICE_OVERDUE && 'error') ||
              (status === INVOICE_STATUS.PARTIALLY_PAID && 'warning') ||
              'default'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </TableCell>
      )}
      {width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM && (
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <div>
                  <MenuItem
                    className="pl-6"
                    onClick={() => {
                      onViewRow();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={'eva:eye-fill'} />
                    View
                  </MenuItem>
                  {contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS ? (
                    <>
                      <MenuItem
                        className="pl-6"
                        onClick={() => {
                          onDuplicateRow();
                          handleCloseMenu();
                        }}
                      >
                        <Iconify icon={'heroicons-solid:document-duplicate'} />
                        Duplicate
                      </MenuItem>
                      <MenuItem
                        className="pl-6"
                        onClick={() => {
                          onEditRow();
                          handleCloseMenu();
                        }}
                      >
                        <Iconify icon={'eva:edit-fill'} />
                        Edit
                      </MenuItem>{' '}
                    </>
                  ) : (
                    <MenuItem
                      className="pl-6"
                      onClick={() => {
                        onDeleteRow();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </div>
                {/* <Divider className="my-2" /> */}
                {/* <MenuItem
                className="pl-6"
                onClick={() => {
                  onArchiveRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Archive
              </MenuItem> */}
              </>
            }
          />
        </TableCell>
      )}
    </TableRow>
  );
}

ViewContactTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onArchiveRow: PropTypes.func,
  onEditRow: PropTypes.func
};
